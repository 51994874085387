<script>
    const defaultTitle = 'ADDC - App Design & Development Conference 2021';
    const defaultTitleGoogle = 'Conference for iOS & Android developers and ' +
        'UX / UI designers';
    const defaultDescription = 'International conference for iOS & Android developers and UI/UX designers, ' +
        'taking place June 23 - 24, 2021.';
    const defaultImage = 'share.jpg';
    const imageRoot = 'https://addconf.com/2021/';

    export default {
        computed: {
            seo() {
                return {};
            },
        },
        metaInfo() {
            return {
                title: this.seo.title ? `${this.seo.title} · ADDC 2021` : defaultTitleGoogle,
                meta: [
                    {
                        name: 'description', vmid: 'description',
                        content: this.seo.description || defaultDescription,
                    },
                    {
                        property: 'og:title', vmid: 'og:title',
                        content: this.seo.title || defaultTitle,
                    },
                    {
                        property: 'og:description', vmid: 'og:description',
                        content: this.seo.description || defaultDescription,
                    },
                    {
                        property: 'og:url', vmid: 'og:url',
                        content: `https://addconf.com/2021/${this.seo.url || ''}`,
                    },
                    {
                        property: 'og:type', vmid: 'og:type',
                        content: 'article',
                    },
                    {
                        property: 'og:image', vmid: 'og:image',
                        content: `${imageRoot}${this.seo.image || defaultImage}`,
                    },
                    {
                        property: 'og:image:width', vmid: 'og:image:width',
                        content: this.seo.imageWidth || '1200',
                    },
                    {
                        property: 'og:image:height', vmid: 'og:image:height',
                        content: this.seo.imageHeight || '630',
                    },
                    {
                        property: 'fb:app_id', vmid: 'fb:app_id',
                        content: '392443007814410',
                    },
                    {
                        property: 'twitter:card', vmid: 'twitter:card',
                        content: 'summary_large_image',
                    },
                    {
                        property: 'twitter:site', vmid: 'twitter:site',
                        content: '@addconf',
                    },
                    {
                        property: 'twitter:creator', vmid: 'twitter:creator',
                        content: '@addconf',
                    },
                    {
                        property: 'twitter:title', vmid: 'twitter:title',
                        content: this.seo.title || defaultTitle,
                    },
                    {
                        property: 'twitter:description', vmid: 'twitter:description',
                        content: this.seo.description || defaultDescription,
                    },
                    {
                        property: 'twitter:image', vmid: 'twitter:image',
                        content: `${imageRoot}${this.seo.image || defaultImage}`,
                    },
                ],
                link: [
                    { rel: 'canonical', href: `https://addconf.com/2021/${this.seo.url || ''}` },
                ],
            };
        },
    };
</script>
