import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueScrollTo from 'vue-scrollto';

import IntercomButton from './components/IntercomButton.vue';
import SubscribeNewsletter from './components/SubscribeNewsletter.vue';
import Footer from './components/Footer.vue';
import NavigationBar from './components/NavigationBar.vue';
import Menu from './components/Menu.vue';
import Tickets from './components/Tickets.vue';

Vue.component('IntercomButton', IntercomButton);
Vue.component('SubscribeNewsletter', SubscribeNewsletter);
Vue.component('Footer', Footer);
Vue.component('NavigationBar', NavigationBar);
Vue.component('Menu', Menu);
Vue.component('Tickets', Tickets);
Vue.config.productionTip = false;
Vue.config.ignoredElements = [ 'tito-button' ];

Vue.use(VueScrollTo);

router.afterEach(( to, from ) => {
    // @ts-ignore
    const googleAnalytics = window.ga;

    if (googleAnalytics) {
        googleAnalytics(() => {
            googleAnalytics('set', 'page', to.path);
            googleAnalytics('send', 'pageview');
        });
    }
});


const fixIdScrolling = {
    watch: {
        $route(to: any, from: any) {
            // @ts-ignore
            const currentRoute = this.$router.currentRoute;
            const idToScrollTo = currentRoute.hash;
            // @ts-ignore
            this.$nextTick(() => {
                if (idToScrollTo && document.querySelector(idToScrollTo)) {
                    // @ts-ignore
                    this.$scrollTo(idToScrollTo, 0, { offset: -120 });
                }
            });
        },
    },
};

const app = new Vue({
    router,
    mixins: [fixIdScrolling],
    render: (h) => h(App),
    mounted() {
        document.dispatchEvent(new Event('addc-render-event'));
    },
}).$mount('#page');

// @ts-ignore
window.snapSaveState = () => {
    // @ts-ignore
    window.document.querySelector('#page').setAttribute('data-server-rendered', 'true');
};
