<template>
    <section class="section section-content section-activities">
        <div class="container grid-lg">

            <div class="">
                <div class="section-title h2">
                    <h3 class="h2">
                        <strong>Activities</strong>
                    </h3>
                    <small class="highlight">
                        Included in your ticket
                    </small>
                    <div class="subtitle">
                        June 27th (Thursday)
                        <div>
                            <small class="text-muted">* One activity per ticket included. <br/>Subject to change. Spots are limited.</small>
                        </div>
                    </div>
                </div>
                <div class="activity-list">
                    <div class="columns">
                        <div class="column col-4 col-sm-6">
                            <div class="card">
                                <div class="card-image">
                                    <img class="img-responsive" src="../assets/img/activities/activity-cooking.jpg" alt="Cooking Experience" />
                                </div>
                                <div class="card-header">
                                    <div class="card-title h6">
                                        <strong>
                                            Mini Spanish Cooking Experience
                                        </strong>
                                        <div class="card-subtitle text-gray">
                                            Private cooking experience with a local chef, learn about some of the local ingredients fundamental to Spanish cuisine, learn how to make some traditional Spanish treats like paella, sangria and tapas, and reward yourself by indulging in testing out your new culinary skills. Spanish Breakfast Experience, and Lunch Experience.
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body" v-if="false">
                                    <div class="">
                                        <dl>
                                            <dt>
                                                When:
                                            </dt>
                                            <dd>
                                                Arrive 15 minutes before your selected class time.
                                            </dd>
                                            <dt>
                                                Duration:
                                            </dt>
                                            <dd>
                                                1.5 hours
                                            </dd>
                                            <dt>
                                                Meeting Point:
                                            </dt>
                                            <dd>
                                                <a href="https://www.google.com/maps/place/Panxa+Plena/@41.3989757,2.2047757,17z/data=!3m1!4b1!4m5!3m4!1s0x12a4a36a61a9cb5b:0xc1b7d4e1c5aa750d!8m2!3d41.3989757!4d2.2069644" target="_blank">Panxa Plena, Carrer Perello 33, 08005, Barcelona</a>.
                                            </dd>
                                            <dt>
                                                How:
                                            </dt>
                                            <dd>
                                                L4 Yellow line to Poblenou station, and a short walk from there.
                                            </dd>
                                            <dt>
                                                Emergency Number:
                                            </dt>
                                            <dd>
                                                If you get lost or for any other reason please call Katie +34 672 654 519 or Kelly +34 625 622 891.
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <!-- <div class="card-footer text-center">
                                    <div class="divider divider-gradient"></div>
                                    <a class="btn btn-primary" href="#!">Reserve a spot</a>
                                </div> -->
                            </div>
                        </div>
                        <div class="column col-4 col-sm-6">
                            <div class="card">
                                <div class="card-image">
                                    <img class="img-responsive" src="../assets/img/activities/activity-walking.jpg" alt="Walking Tour" />
                                </div>
                                <div class="card-header">
                                    <div class="card-title h6">
                                        <strong>
                                            Barcelona Walking Tour
                                        </strong>
                                        <div class="card-subtitle text-gray">
                                            2 Hour entertaining and informative walking tour of Barcelona's Gothic Quarter. Our expert guides will take you around the city and give you an insight into the history of the area. You will learn fun facts and be kept entertained from start to finish. As recommended by Trip advisor, Get your Guide and The Lonely Planet.
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body" v-if="false">
                                    <div class="">
                                        <dl>
                                            <dt>
                                                When:
                                            </dt>
                                            <dd>
                                                Arrive 15 minutes before your selected class time.
                                            </dd>
                                            <dt>
                                                Duration:
                                            </dt>
                                            <dd>
                                                2 hours
                                            </dd>
                                            <dt>
                                                Meeting Point:
                                            </dt>
                                            <dd>
                                                <a href="https://www.google.com/maps/place/Stoke+Threads/@41.3805089,2.1877216,17z/data=!4m12!1m6!3m5!1s0x12a4a3efe5682fe9:0x8a0eeb615408a458!2sStoke+Threads!8m2!3d41.3804136!4d2.1877654!3m4!1s0x12a4a3efe5682fe9:0x8a0eeb615408a458!8m2!3d41.3804136!4d2.1877654" target="_blank">Stoke Threads, 4 Carrer de la Maquinista, Barceloneta</a>. The Stoke Travel guide will meet you there.
                                            </dd>
                                            <dt>
                                                How:
                                            </dt>
                                            <dd>
                                                L4 yellow line to Barceloneta and a 5 minute walk from there.
                                            </dd>
                                            <dt>
                                                Emergency Number:
                                            </dt>
                                            <dd>
                                                If you get lost or for any other reason please call Katie +34 672 654 519 or Kelly +34 625 622 891.
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <!-- <div class="card-footer text-center">
                                    <div class="divider divider-gradient"></div>
                                    <a class="btn btn-primary" href="#!">Reserve a spot</a>
                                </div> -->
                            </div>
                        </div>
                        <div class="column col-4 col-sm-6">
                            <div class="card">
                                <div class="card-image">
                                    <img class="img-responsive" src="../assets/img/activities/activity-art.jpg" alt="Streat Art Tour" />
                                </div>
                                <div class="card-header">
                                    <div class="card-title h6">
                                        <strong>
                                            Barcelona Street Art Walking Tour
                                        </strong>
                                        <div class="card-subtitle text-gray">
                                            Following the end of Franco’s dictatorship, rebellion and freedom of expression were firmly embraced in Spain, and creatives continue to be inspired by the protest culture and embracing their civil liberties, resulting in Barcelona's thriving street art scene acknowledged recently as one of the top 10 graffiti art cities world wide. During the tour we will explore the legal and illegal sides of street art, visit what have become murals, skate parks and galleries as well as stopping to take plenty of photos along the way.
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body" v-if="false">
                                    <div class="">
                                        <dl>
                                            <dt>
                                                When:
                                            </dt>
                                            <dd>
                                                Arrive 15 minutes before your selected class time.
                                            </dd>
                                            <dt>
                                                Duration:
                                            </dt>
                                            <dd>
                                                1.5 hours
                                            </dd>
                                            <dt>
                                                Meeting Point:
                                            </dt>
                                            <dd>
                                                <a href="https://www.google.com/maps/place/Stoke+Threads/@41.3805089,2.1877216,17z/data=!4m12!1m6!3m5!1s0x12a4a3efe5682fe9:0x8a0eeb615408a458!2sStoke+Threads!8m2!3d41.3804136!4d2.1877654!3m4!1s0x12a4a3efe5682fe9:0x8a0eeb615408a458!8m2!3d41.3804136!4d2.1877654" target="_blank">Stoke Threads, 4 Carrer de la Maquinista, Barceloneta</a>. The Stoke Travel Guide will meet you there.
                                            </dd>
                                            <dt>
                                                How:
                                            </dt>
                                            <dd>
                                                L4 yellow line to Barceloneta and a 5 minute walk from there.
                                            </dd>
                                            <dt>
                                                Emergency Number:
                                            </dt>
                                            <dd>
                                                If you get lost or for any other reason please call Katie +34 672 654 519 or Kelly +34 625 622 891.
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <!-- <div class="card-footer text-center">
                                    <div class="divider divider-gradient"></div>
                                    <a class="btn btn-primary" href="#!">Reserve a spot</a>
                                </div> -->
                            </div>
                        </div>
                        <div class="column col-4 col-sm-6">
                            <div class="card">
                                <div class="card-image">
                                    <img class="img-responsive" src="../assets/img/activities/activity-boat.jpg" alt="Skyline Boat Experience" />
                                </div>
                                <div class="card-header">
                                    <div class="card-title h6">
                                        <strong>
                                            Port and Barcelona Skyline Boat Tour
                                        </strong>
                                        <div class="card-subtitle text-gray">
                                            Boat trip from the famous Port Vell Marina, where you will see panoramic views of Barcelona. Enjoy the tranquility of the sea, feel the breeze and take in some sun, 1 drink included.
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body" v-if="false">
                                    <div class="">
                                        <dl>
                                            <dt>
                                                When:
                                            </dt>
                                            <dd>
                                                Arrive 15 minutes before your selected class time.
                                            </dd>
                                            <dt>
                                                Duration:
                                            </dt>
                                            <dd>
                                                1 hour
                                            </dd>
                                            <dt>
                                                Meeting Point:
                                            </dt>
                                            <dd>
                                                <a href="https://www.google.com/maps/search/Port+Vell,+Moll+de+les+Drassanes,+08039+Barcelona.+BCNaval/@41.3753342,2.1777338,17z/data=!3m1!4b1" target="_blank">Port Vell, Moll de les Drassanes, 08039 Barcelona</a>.
                                            </dd>
                                            <dt>
                                                How:
                                            </dt>
                                            <dd>
                                                L3 Green line to Drassanes, and a short walk from there.  Walk to the bottom of Las Ramblas,  in front of the columbus monument you will see the Marina.  Look for the Green Ticket Office of BCNaval, the Stoke Travel guide will meet you there.
                                            </dd>
                                            <dt>
                                                Emergency Number:
                                            </dt>
                                            <dd>
                                                If you get lost or for any other reason please call Katie +34 672 654 519 or Kelly +34 625 622 891.
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <!-- <div class="card-footer text-center">
                                    <div class="divider divider-gradient"></div>
                                    <a class="btn btn-primary" href="#!">Reserve a spot</a>
                                </div> -->
                            </div>
                        </div>
                        <div class="column col-4 col-sm-6">
                            <div class="card">
                                <div class="card-image">
                                    <img class="img-responsive" src="../assets/img/activities/activity-adrenaline.jpg" alt="Power Boat Experience" />
                                </div>
                                <div class="card-header">
                                    <div class="card-title h6">
                                        <strong>
                                            Adrenalin Power Boat Experience - great views too!
                                        </strong>
                                        <div class="card-subtitle text-gray">
                                            Jump on the Adrenalin power boat, see the amazing views of Barcelona at whilst jumping the waves, and doing spins and turns at great speeds. Super safe and super fun. 1 drinks included.
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body" v-if="false">
                                    <div class="">
                                        <dl>
                                            <dt>
                                                When:
                                            </dt>
                                            <dd>
                                                Arrive 15 minutes before your selected class time.
                                            </dd>
                                            <dt>
                                                Duration:
                                            </dt>
                                            <dd>
                                                1 hour
                                            </dd>
                                            <dt>
                                                Meeting Point:
                                            </dt>
                                            <dd>
                                                <a href="https://www.google.com/maps/search/Port+Vell,+Moll+de+les+Drassanes,+08039+Barcelona.+BCNaval/@41.3753342,2.1777338,17z/data=!3m1!4b1" target="_blank">Port Vell, Moll de les Drassanes, 08039 Barcelona</a>.
                                            </dd>
                                            <dt>
                                                How:
                                            </dt>
                                            <dd>
                                                L3 Green line to Drassanes, and a short walk from there.  Walk to the bottom of Las Ramblas,  in front of the columbus monument you will see the Marina.  Look for the Green Ticket Office of BCNaval, the Stoke Travel guide will meet you there.
                                            </dd>
                                            <dt>
                                                Emergency Number:
                                            </dt>
                                            <dd>
                                                If you get lost or for any other reason please call Katie +34 672 654 519 or Kelly +34 625 622 891.
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <!-- <div class="card-footer text-center">
                                    <div class="divider divider-gradient"></div>
                                    <a class="btn btn-primary" href="#!">Reserve a spot</a>
                                </div> -->
                            </div>
                        </div>
                        <div class="column col-4 col-sm-6">
                            <div class="card">
                                <div class="card-image">
                                    <img class="img-responsive" src="../assets/img/activities/activity-beach.jpg" alt="Beach Games" />
                                </div>
                                <div class="card-header">
                                    <div class="card-title h6">
                                        <strong>
                                            Beach Activities
                                        </strong>
                                        <div class="card-subtitle text-gray">
                                            Hey we're in Barcelona and it's summer, where's a better place to be than on the beach? There will be beach games galore including Volleyball, dodgeball, and tag rugby, or if you're not feeling sporty just network, and take in some rays. Snacks and some drinks included.
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body" v-if="false">
                                    <div class="">
                                        <dl>
                                            <dt>
                                                When:
                                            </dt>
                                            <dd>
                                                Arrive 15 minutes before your selected class time.
                                            </dd>
                                            <dt>
                                                Duration:
                                            </dt>
                                            <dd>
                                                2 hours
                                            </dd>
                                            <dt>
                                                Meeting Point:
                                            </dt>
                                            <dd>
                                                <a href="https://www.google.com/maps/d/viewer?mid=1xr95_wyfGTqDl-ijvfE1Q3wpnRPWqVpa&ll=41.39593130000003%2C2.209274800000003&z=17" target="_blank">Platja del Bogatell, Paseo Maritimo del Bogatell, 08005, Barcelona</a>.
                                            </dd>
                                            <dt>
                                                How:
                                            </dt>
                                            <dd>
                                                L4 Yellow line to Llacuna, and a short walk to the beach from there.
                                            </dd>
                                            <dt>
                                                Emergency Number:
                                            </dt>
                                            <dd>
                                                If you get lost or for any other reason please call Katie +34 672 654 519 or Kelly +34 625 622 891.
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                                <!-- <div class="card-footer text-center">
                                    <div class="divider divider-gradient"></div>
                                    <a class="btn btn-primary" href="#!">Reserve a spot</a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'Activities',
    };
</script>

<style scoped>

</style>
