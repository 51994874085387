<template>
    <div>
        <div v-for="(line, index) in mappedSchedule" class="conference-day" v-bind:id="line.id">

            <div class="section-title h3" v-if="showDayPartTitle">
                <div>
                    <strong>{{ line.title }}</strong>
                </div>
                <small class="highlight" v-if="line.highlight">
                    {{ line.highlight }}
                </small>
                <div class="subtitle text-muted">
                    {{ line.dateTitle }}
                </div>
                <div class="subtitle text-muted" v-if="line.summary">
                    {{ line.summary }}
                </div>
            </div>

            <div class="schedule-day">
                <div class="columns grid-center">
                    <div v-for="part in line.splittedSlots" class="column col-6 col-md-12">
                        <div v-for="item in part" class="columns schedule-item">
                            <div class="column col-2 schedule-item-time" v-if="item.timeTitle">
                                <div class="section-title h5 text-muted">
                                    {{ item.timeTitle }}
                                </div>
                            </div>
                            <div class="column col-10 schedule-item-content">
                                <div class="section-title h5">
                                    <div v-if="item.talk">
                                        <router-link v-bind:to="`/schedule/${item.talk.slug}`">
                                            {{ item.talk.title }}
                                        </router-link>
                                    </div>
                                    <div v-if="item.workshop">
                                        <router-link v-bind:to="`/schedule/${item.workshop.slug}`">
                                            {{ item.workshop.title }}
                                        </router-link>  (cca 3 hours)
                                    </div>
                                    <span v-if="!item.talk && !item.url">
                                                        {{ item.title }}
                                    </span>
                                    <span v-if="!item.talk && item.url">
                                                        <router-link v-bind:to="item.url">
                                                            {{ item.title }}
                                                        </router-link>
                                    </span>
                                    <span v-if="!item.talk && item.speakers && item.speakers.length > 0">
                                        Talk
                                    </span>

                                  <div class="text-muted" v-if="item.description">
                                        <small>
                                            {{ item.description }}
                                        </small>
                                    </div>
                                    <div class="text-muted" v-if="item.description2">
                                        <small>
                                            {{ item.description2 }}
                                        </small>
                                    </div>
                                </div>
                                <div class="speaker-nav">
                                    <div v-for="speaker in (item.speakers||[])">
                                        <div class="tile tile-centered">
                                            <div class="tile-icon">
                                                <img class="avatar avatar-lg" v-bind:src="pathForImage(speaker.imageAvatar)" />
                                            </div>
                                            <div class="tile-content">
                                                <strong>{{ speaker.firstName }}</strong> {{ speaker.lastName }}
                                                <div>
                                                    <small class="text-muted">
                                                        {{ speaker.company }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="index !== schedule.length-1" class="divider"></div>

        </div>
    </div>
</template>
<script>
    import { getTalkBySlug } from '../store/talks';
    import { getWorkshopBySlug } from '../store/workshops';
    import { getSpeakerBySlug } from '../store/speakers';

    export default {
        name: 'Schedule',
        props: {
            schedule: Array,
            showDayPartTitle: Boolean,
        },
        data() {
            return {
                mappedSchedule: this.schedule.map((line) => {
                    const slots = line.slots.map((slot) => {
                        if (slot.talkSlug) {
                            slot.talk = getTalkBySlug(slot.talkSlug);

                            if (!slot.talk) {
                              console.error(`Cannot find a talk ${slot.talkSlug}`);
                            }
                            slot.speakers = slot.talk.speakersSlugs.map((slug) => getSpeakerBySlug(slug));
                        }

                        if (slot.speakerSlug) {
                            slot.speakers = [getSpeakerBySlug(slot.speakerSlug)];
                        }

                        if (slot.workshopSlug) {
                            slot.workshop = getWorkshopBySlug(slot.workshopSlug);
                            slot.speakers = slot.workshop.speakersSlugs.map((slug) => getSpeakerBySlug(slug));
                        }
                        return slot;
                    });


                    if (slots.length > 3) {
                        const numberOfSlotsInFirstHalf = Math.ceil(slots.length / 2);

                        line.splittedSlots = [
                            slots.slice(0, numberOfSlotsInFirstHalf),
                            slots.slice(numberOfSlotsInFirstHalf),
                        ];
                    } else {
                        line.splittedSlots = [slots];
                    }
                    return line;
                }),
            };
        },

        methods: {
            pathForImage(imageName) {
                return require(`../assets/img/speakers/${imageName}`);
            },
        },
    };
</script>

<style scoped>

</style>
