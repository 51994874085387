import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import routes from './store/routes';
import VueScrollTo from 'vue-scrollto';

Vue.use(Router);
Vue.use(Meta);

const vueRoutes = routes.map((route: any) => ({
    path: route.path,
    component: () => import(`./views/${route.view}.vue`),
}));

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: vueRoutes,
    scrollBehavior(to: any, from: any, savedPosition: any) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});
