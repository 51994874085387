export interface Workshop {
    slug: string;
    title: string;
    description: string;
    speakersSlugs: string[];
}

const workshops: Workshop[] = [
    {
        slug: 'design-research-beyond-user-testing',
        title: 'Research workshop: Design Research Beyond User Testing',
        description: 'This ½ day workshop provides a glimpse into the world of design research. In addition to “user testing,” we’ll cover the spectrum of research approaches. Participants will leave with new ways of thinking about research, equipped with tips and tricks to improve their research practices. Appropriate for those conducting research or making decisions based on findings.\n\nRequired materials::\n\nNo need for a laptop, just be prepared to speak to each other, listen and take some notes. Pens and notepads will be provided.',
        speakersSlugs: [
            'lindsey',
        ],
    },
    {
        slug: 'towards-good-coverage-in-android',
        title: 'Android development workshop: Towards Good Coverage in Android',
        description: 'This half-day hands-on workshop is about writing code together and testing it. We will write together a simple but well-architected application using Android Architecture Components. And we will see how good architecture helps to test, but not from a theoretical perspective, but by actually writing unit tests for the different pieces of the application. This is an important first step towards achieving real continuous integration and improving the quality of your apps.\n\nRequired materials::\n\nLaptop with Android Studio set up and ready to craft apps. Pens and notepads will be provided.',
        speakersSlugs: [
            'jorge',
        ],
    },
    {
        slug: 'seeing-map-and-flatmap-as-swift-design-patterns',
        title: 'iOS workshop: Seeing Map and FlatMap as Swift Design Patterns',
        description: 'In this workshop we will explore the map, flatMap, and compactMap functions that ship with Swift 5 and write one of our own. We’ll look at when you need these higher order functions and how you create and use them.\n\nRequired materials::\n\nLaptop with Swift 5 (XCode at least 10.2). Pens and notepads will be provided.',
        speakersSlugs: [
            'daniel',
        ],
    },
    {
        slug: 'building-design-systems-with-googles-material-design',
        title: 'Design workshop: Building Design Systems with Google’s Material Design',
        description: 'Material Design is Google’s adaptable design system that anyone can use to quickly build beautiful and usable digital products. In Material, a selection of flexible design components—backed by open-source code—sit on top of foundational design guidance and best-practices. Material Theming adds dimension to the system with subsystems like color, shape, and type, which can be adapted to create a custom Material theme for your product’s unique identity.\n\nThis workshop, suitable for all experience levels, will provide an overview of Material’s capabilities and hands-on exercises where you’ll implement Material Theming and emerge with real code. Through these activities, concepts, and examples, you’ll be prepared to express your existing brand with Material or create something entirely new.\n\nWho should attend: Designers, developers, and product stakeholders of all experience levels interested in learning about Material Design, Material Theming, and hands-on experience using MDC-Web and Gallery.\n\nWhen we’re done, you will:\n\n* Be familiar with the Material Design system and its capabilities\n* Understand the principles and practical applications of Material Theming, including how subsystems such as layout, color, typography, and shape map to components\n* Understand the basics of Material Components for Web\n* Walk away with a customized theme, implemented in real code\n\nRequired materials:\n\nLaptop with web browser. We will be using MDC-Web for the workshop. If you are an Android developer, we will have content that you can use in Android Studio. Pens and notepads will be provided.',
        speakersSlugs: [
            'kunal', 'javier',
        ],
    },
];

export const getWorkshopBySpeakerSlug: (slug: string) => Workshop|undefined = (slug) => {
    return workshops.filter((workshop) => workshop.speakersSlugs.includes(slug))[0];
};

export const getWorkshopBySlug: (slug: string) => Workshop|undefined = (slug) => {
    return workshops.filter((workshop) => workshop.slug === slug)[0];
};

export default workshops;
