<template>

    <div id="page">
        <router-view></router-view>
    </div>

</template>

<style lang="scss">
    @import 'assets/styles/main';
</style>

<script>
    import EventStructuredData from './components/EventStructuredData';

    const load3rdPartyStuffIfNotPrerendering = () => {
        if (navigator.userAgent === 'ReactSnap') {
            return;
        }

        /* tslint:disable */

        // facebook
        window.fbAsyncInit = () => {
            window.FB.XFBML.parse();
        };

        (function(d, s, id) {
            // @ts-ignore
            var js, fjs = d.getElementsByTagName(s)[0];
            // @ts-ignore
            if (d.getElementById(id)) return;
            // @ts-ignore
            js = d.createElement(s); js.id = id;
            // @ts-ignore
            js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.1&appId=392443007814410&autoLogAppEvents=1';
            // @ts-ignore
            fjs.parentNode.insertBefore(js, fjs);
            // @ts-ignore
        }(document, 'script', 'facebook-jssdk'));

        // twitter
        const twitterScript = document.createElement('script');
        twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js');
        twitterScript.async = true;
        document.head.appendChild(twitterScript);

        // intercom
        window.intercomSettings = {
            app_id: 'hs8smaas',
        };
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/hs8smaas';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()


        // TrustPilot
        const trustpilotScript = document.createElement('script');
        trustpilotScript.setAttribute('src', 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js');
        trustpilotScript.async = true;
        trustpilotScript.onload = () => {
            if (typeof window.trustpilotCallback !== 'undefined') {
                window.trustpilotCallback();
            }
        };

        document.head.appendChild(trustpilotScript);

        // Tito
        const titoScript = document.createElement('script');
        titoScript .setAttribute('src', 'https://js.tito.io/v1');
        titoScript .async = true;
        document.head.appendChild(titoScript);
        window.titoWidgetCallback = function(){
            TitoWidget.build_widgets = false;
        }

        /* tslint:enable */
    };

    export default {
        mounted() {
            this.$nextTick(() => {
                load3rdPartyStuffIfNotPrerendering();
            });
        },
        components: {
          'event-structed-data': EventStructuredData,
        },
    };
</script>
