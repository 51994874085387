<template>
<div class="page page-jobs">

<Menu />

<NavigationBar back-link="/" back-text="Home"/>

<section class="section section-jobs">

<div class="container grid-md inverted">
  <div class="section-title h1 inverted">
    <h1 class="h1">
      <strong>Jobs</strong>
    </h1>
    <div class="subtitle">
      Our sponsors are looking for top notch talent to join their team.
    </div>
  </div>

  <div>
    <div class="columns grid-left text-left">

      <div class="column col-4 col-sm-6 col-md-4">
        <a href="https://beams.fm/jobs-senior-designer" target="_blank" class="job-card">
          <img src="../assets/img/speakers/brand-beams.svg" class="brand img-responsive" />
          <H4>
            Senior Product Designer
          </H4>
          <small class="text-muted">Barcelona / Berlin</small>
        </a>
      </div>
      <div class="column col-4 col-sm-6 col-md-4">
        <a href="https://beams.fm/jobs-software-engineer" target="_blank" class="job-card">
          <img src="../assets/img/speakers/brand-beams.svg" class="brand img-responsive" />
          <H4>
            Software Engineer
          </H4>
          <small class="text-muted">Barcelona / Berlin</small>
        </a>
      </div>
    </div>
  </div>

</div>

</section>

<Footer/>
</div>
</template>
<script>
  import MetaData from '../mixins/MetaData.vue';

  export default {
    mixins: [MetaData],
    computed: {
      seo() {
        return {
          title: 'Jobs-Beams',
          url: 'jobs-beams/',
        };
      },
    },
  };
</script>
