<template>
    <header class="navbar">

        <section class="navbar-section">
            <a v-on:click.prevent="$router.push({ path: '/' })" href="/2021/">
                <div class="addc-icon">
                    <img class="img-responsive" src="../assets/img/logo-white.svg" alt="ADDC" />
                </div>
            </a>
            <div class="divider-vert hide-sm" v-if="backLink && backText"></div>
            <router-link class="hide-sm" v-if="backLink && backText" v-bind:to="backLink">
                <i class="icon icon-arrow-left"></i> {{ backText }}
            </router-link>
        </section>

        <!-- <section class="navbar-section">
            <a v-on:click.prevent="$router.push({ path: '/' })" href="/2021/">
                <div class="addc-icon">
                    <img class="img-responsive" src="../assets/img/addc-icon-navbar.svg" alt="ADDC" />
                </div>
            </a>
        </section> -->

        <!--<section class="navbar-section">-->
        <!--<a href="#!" class="btn btn-action action-close">-->
        <!--<i class="icon icon-cross"></i>-->
        <!--</a>-->
        <!--</section>-->

        <section class="navbar-section">
            <!-- <a href="#!" class="btn btn-action action-close">
                <i class="icon icon-menu"></i>
            </a> -->

        </section>
    </header>
</template>

<script>
    export default {
        props: ['backLink', 'backText' ],
    };
</script>
