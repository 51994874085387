<template>
    <div>

        <Menu />

        <NavigationBar />

        <div class="toast toast-prev-edition">
            <a class="btn btn-link" href="https://addconf.com/2019/" target="_blank">
                <div class="columns">
                    <div class="column hide-sm">
                        <img class="" src="../assets/img/video-thumbnail.jpg" alt="ADDC 2019" />
                    </div>
                    <div class="column">
                        <strong>ADDC 2019</strong> | Previous edition
                        <small>
                            Watch the talks and conference videos
                        </small>
                    </div>
                </div>
            </a>
        </div>

        <section class="section section-intro section-inverted">
            <div class="container grid-md">


<!--                <a class="next-edition-promo" href="https://faq.addconf.com/en/articles/3829071-addc-rescheduled-to-june-2021" target="_blank">-->
<!--                    <img class="img-responsive" src="https://addconf.com/2021/share.jpg" alt="Logo" />-->
<!--                    <div class="promo-content">-->
<!--                        <div class="section-title h3">-->
<!--                            <div>-->
<!--                                <strong>ADDC rescheduled</strong>  to June 2021-->
<!--                            </div>-->
<!--                            <div class="subtitle">-->
<!--                                The ADDC team has been closely monitoring the developments related to the coronavirus disease (COVID-19) outbreak and the potential impact on international travel to ADDC 2021. <strong>Given the circumstances and currently available information we made a hard decision to postpone this year’s event to June 23-25, 2021.</strong>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="btn btn-primary btn-lg">-->
<!--                            Read more-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </a>-->


                <img class="logo img-responsive" src="../assets/img/logo-white.svg" alt="Logo" />

                <div class="section-header">
                    <div class="section-title h2">
                        <h1 class="h2">

                            <strong>App Design</strong> & <strong>Development</strong> conference | <span class="highlight"><strong>June 23-24</strong>, 2021<br/>Remote edition</span>

                        </h1>
                        <h2 class="subtitle">
                            Single-track international conference for iOS & Android developers and UX/UI designers.
                        </h2>
                    </div>
                </div>
                <div class="share">
                    <div>
                        <div class="fb-share-button" data-href="https://addconf.com/" data-layout="button" data-size="large" data-mobile-iframe="true"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Faddconf.com%2F2021%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Share</a></div>
                    </div>
                    <div>
                        <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="twitter-share-button" data-size="large" data-text="Check out the App Design &amp; Development Conference 2021 in Barcelona" data-url="https://addconf.com/" data-via="addconf" data-dnt="true" data-show-count="false">Tweet</a>
                    </div>

                </div>

                <div class="banner-live">
                    <div class="columns">
                        <div class="column col-mx-auto col-md-12 banner-live-left">
                            <H3>
                               🙋🏻🙋🏽‍♀️ We are live now!
                            </H3>
                        </div>
                        <div class="column col-mx-auto col-md-12 banner-live-right">
                            <a class="btn btn-lg" href="/2021/schedule" target="_blank">
                                Schedule
                            </a>
                            <a class="btn btn-primary btn-lg ml-2" href="https://ti.to/addc/2021" target="_blank">
                                Watch now
                            </a>
                        </div>
                    </div>
                </div>

                <div class="section-extra intro-recap-video">
                    <div class="section-title h4">
                        <strong>Short video from ADDC 2019</strong>
                    </div>
                    <div class="talk-video">
                        <div class="card">
                            <iframe width="560" height="315" v-bind:src="`https://www.youtube.com/embed/QwLqSAasd4E`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="actions">
                        <!-- <router-link class="btn btn-primary" to="/talks">
                            Watch the talks
                        </router-link> -->
                        <a href="/2019/talks" target="_blank" class="btn btn-primary">
                            ADDC 2019 talks
                        </a>
                        <a href="https://www.facebook.com/pg/addconfcom/photos/?tab=albums&ref=page_internal" target="_blank" class="btn">
                            ADDC 2019 photos
                        </a>
                    </div>
                </div>

                <div class="section-extra">
                    <div class="columns">
                        <div class="column col-mx-auto">
                            <div class="section-title h4">
                                <div>
                                    <strong>Newsletter</strong>
                                </div>
                                <div class="subtitle">
                                    Receive the latest promotions, news and special announcements straight to your inbox.
                                </div>
                            </div>
                            <div class="newsletter">
                                <SubscribeNewsletter/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section section-content section-why">
            <div class="container grid-lg">

                <div class="columns">
                    <div class="column">
                        <div class="gallery">
                            <img class="gallery-front img-responsive" src="../assets/img/why-front.jpg" alt="Why" />
                            <img class="gallery-back img-responsive" src="../assets/img/why-back.jpg" alt="Why" />
                        </div>
                    </div>
                    <div class="column">
                        <div class="section-title h3">
                            <h3 class="h3">
                                Why <strong>ADDC</strong>
                            </h3>
                            <div class="subtitle">
                                App designers and developers around the world work side by side every day to build some of the most incredible and innovative applications. Yet our potential for synergy is so often overlooked.
                            </div>
                            <div class="subtitle">
                                ADDC aims to create an opportunity for designers and developers to meet, find new ways to work together and get inspired in an open, inclusive and collaborative space.
                            </div>
                            <div class="subtitle">
                                <strong><span class="highlight">Our talks are crafted to be valuable for both designers & developers.</span></strong>
                            </div>
                        </div>
                        <div class="text-center">
                            <div class="divider divider-gradient"></div>
                            <label class="text-muted">
                                Do you have any questions?
                            </label>
                            <div class="actions">
                                <IntercomButton class="btn btn-primary">
                                    <i class="icon icon-bubble"></i>
                                    Open chat
                                </IntercomButton>
                                <router-link to="/sponsors" class="btn">
                                    🤗 Become a sponsor
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <Speakers />
        <ScheduleHome/>
<!--        <Party />-->
        <Sponsors />
        <!-- <Activities /> -->
        <!--<ActivitiesSuccess />-->
        <!--<ActivitiesError />-->
<!--        <Venue />-->
        <!-- <TrustPilot /> -->
        <Tickets/>
<!--        <Accommodation />-->
        <Footer/>
<!--        <EventStructuredData/>-->
    </div>
</template>

<script>
    import EventStructuredData from '../components/EventStructuredData';
    import SubscribeNewsletter from '../components/SubscribeNewsletter';
    import Accommodation from '../components/Accommodation.vue';
    import Sponsors from '../components/Sponsors';
    import Venue from '../components/Venue.vue';
    import ScheduleHome from '../components/ScheduleHome.vue';
    import Speakers from '../components/Speakers.vue';
    import TrustPilot from '../components/TrustPilot';
    import MetaData from '../mixins/MetaData.vue';
    import Social from '../mixins/Social.vue';
    import Activities from '../components/Activities.vue';
    import ActivitiesSuccess from '../components/ActivitiesSuccess.vue';
    import ActivitiesError from '../components/ActivitiesError.vue';
    import Party from '../components/Party.vue';

    export default {
        mixins: [MetaData, Social],
        components: {
            TrustPilot,
            Speakers,
            ScheduleHome,
            Venue,
            Sponsors,
            Accommodation,
            SubscribeNewsletter,
            EventStructuredData,
            Activities,
            ActivitiesSuccess,
            ActivitiesError,
            Party,
        },
    };
</script>
