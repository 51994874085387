export interface Route {
    path: string;
    view: string;
}

const routes: Route[] = [
    { path: '/',                 view: 'Home' },
    { path: '/impressum',        view: 'Impressum' },
    { path: '/become-a-sponsor', view: 'Become-A-Sponsor' },
    { path: '/tickets',          view: 'Tickets' },
    { path: '/speakers',         view: 'Speakers' },
    { path: '/schedule',         view: 'Schedule' },
    { path: '/sponsors',         view: 'Sponsors' },
    { path: '/accommodation',    view: 'Accommodation' },
    { path: '/speakers/:slug',   view: 'Speaker' },
    { path: '/schedule/:slug',   view: 'Talk' },
    { path: '/talks',            view: 'Talks' },
    { path: '/jobs',             view: 'Jobs' },
    { path: '/jobs-beams',       view: 'Jobs-Beams' },
];

export default routes;
