export interface Speaker {
    slug: string;
    firstName: string;
    lastName: string;
    headline: string;
    imageAvatar: string;
    imageBackground: string;
    imageCard: string;
    imageCompany?: string;
    company?: string;
    bio: string;
    twitter?: string;
    linkedin?: string;
    facebook?: string;
    hidden?: boolean;
}

const speakers: Speaker[] = [
    {
        slug: 'erika',
        firstName: 'Erika',
        lastName: 'Leon',
        imageAvatar: 'avatar-erika-leon.jpg',
        imageBackground: 'bg-erika-leon.jpg',
        imageCard: 'card-erika-leon.jpg',
        imageCompany: 'brand-wallapop.svg',
        headline: 'Product designer',
        company: 'Wallapop',
        bio: 'Erika is a product designer originally from Los Angeles and currently based in Barcelona. She is currently working in the Engagement team at Wallapop, creating lovable experiences that allow people throughout Spain to buy and sell secondhand goods. She believes strongly that technology should be easy to use and should enable interactions between people that allow them to live a more meaningful life.',
        linkedin: 'erikamleon',
    },
    {
        slug: 'daniel',
        firstName: 'Daniel',
        lastName: 'Steinberg',
        imageAvatar: 'avatar-daniel-steinberg.jpg',
        imageBackground: 'bg-daniel-steinberg.jpg',
        imageCard: 'card-daniel-steinberg.jpg',
        imageCompany: 'brand-dimsumthinking.svg',
        headline: 'Storyteller',
        company: 'Dim Sum Thinking',
        bio: 'Daniel is the author of more than a dozen books including the best selling books A SwiftUI Kickstart, A Swift Kickstart, and Dear Elena. He has written apps for the iPhone and the iPad since the SDKs first appeared and has written programs for the Mac all the way back to System 7. Daniel presents iPhone, Cocoa, and Swift training and consults through his company Dim Sum Thinking. When he\'s not coding or talking about coding for the Mac, the iPhone, and the iPad he\'s probably cooking, baking bread, or hanging out with friends.',
        linkedin: 'dimsumthinking',
        twitter: 'dimsumthinking',
        facebook: 'dimsumthinking',
    },
    {
        slug: 'frances',
        firstName: 'Frances',
        lastName: 'Biedenharn',
        imageAvatar: 'avatar-frances-biedenharn.jpg',
        imageBackground: 'bg-frances-biedenharn.jpg',
        imageCard: 'card-frances-biedenharn.jpg',
        imageCompany: 'brand-roll20.svg',
        headline: 'UX Designer',
        company: 'Roll20',
        bio: 'Originally from Jackson, Mississippi, I moved to NYC after learning that UX was a real job. I work on a mobile, cross-platform design for virtually augmented ttrpg\'s. I love to travel, but when that isn\'t possible, I\'ll head to the future in a good sci-fi novel or the past in architectural history.',
        twitter: 'DesignoFran',
        linkedin: 'frncsb/',
    },
    {
        slug: 'tamas',
        firstName: 'Tamás',
        lastName: 'Zahola',
        imageAvatar: 'avatar-tamas-zahola.jpg',
        imageBackground: 'bg-tamas-zahola.jpg',
        imageCard: 'card-tamas-zahola.jpg',
        imageCompany: 'brand-shapr3d.svg',
        headline: 'iOS Engineer',
        company: 'Shapr3D',
        bio: 'I\'ve spent the last decade working on various iOS applications. I was always fascinated by the rich creative capabilities of the iOS software platform. I\'m currently working on the Shapr3D CAD system for iPadOS and macOS.',
        linkedin: 'tamás-zahola-0aa1b955',
        facebook: 'zahola',
    },
    {
        slug: 'eliza',
        firstName: 'Eliza',
        lastName: 'Camber',
        imageAvatar: 'avatar-eliza-camber.jpg',
        imageBackground: 'bg-eliza-camber.jpg',
        imageCard: 'card-eliza-camber.jpg',
        imageCompany: 'brand-pixplicity.svg',
        headline: 'Android developer',
        company: 'Pixplicity',
        bio: 'Eliza is an Android developer at Pixplicity and a Google Developer Expert on the Assistant. She has been actively supporting the tech communities for almost 10 years, as a speaker and an organizer. Her big passion is to explore and play with new technologies while she enjoys travelling, and attending conferences and meetups.',
        linkedin: 'elizacamber',
        twitter: 'ElizaCamber',
    },
    {
        slug: 'vincent',
        firstName: 'Vincent',
        lastName: 'Pradeilles',
        imageAvatar: 'avatar-vincent-pradeilles.jpg',
        imageBackground: 'bg-vincent-pradeilles.jpg',
        imageCard: 'card-vincent-pradeilles.jpg',
        imageCompany: 'brand-worldline.svg',
        headline: 'iOS Developer',
        company: 'Worldline',
        bio: 'Vincent started working on iOS apps back in 2011. For the last years, he\'s been working at Worldline, where he contributes to building great apps for major French banks. He loves Swift and enjoys sharing about it on the Internet. Most recently, he started a YouTube channel to share his knowledge of Swift and iOS. He\'s also the one behind the Twitter account @ios_memes.',
        linkedin: 'vincentpradeilles',
        twitter: 'v_pradeilles',
    },
    {
        slug: 'tonia',
        firstName: 'Tonia',
        lastName: 'Sun',
        imageAvatar: 'avatar-tonia-sun.jpg',
        imageBackground: 'bg-tonia-sun.jpg',
        imageCard: 'card-tonia-sun.jpg',
        imageCompany: 'brand-beams.svg',
        headline: 'Senior Product Manager',
        company: 'Beams',
        bio: 'Tonia is a senior product manager at Beams in Berlin, where she is building a new social audio experience through collaborative micro podcasting. Originally from the Bay Area, she started her product career in health tech, leading growth product at Oscar Health in New York and building a digital therapeutics program at a startup in Berlin, and was once a management consultant at Bain. She is passionate about using technology to foster creativity and connection around the world. She\'s always open to connecting with others over travels off the beaten path, natural wine, and art and painting.',
        linkedin: 'tonia-sun-a5a34a23',
        twitter: 'toniajsun',
    },
    {
        slug: 'jason',
        firstName: 'Jason',
        lastName: 'van der Merwe',
        imageAvatar: 'avatar-jason-merwe.jpg',
        imageBackground: 'bg-jason-merwe.jpg',
        imageCard: 'card-jason-merwe.jpg',
        imageCompany: 'brand-strava.svg',
        headline: 'Engineering Manager',
        company: 'Strava',
        bio: 'Born in South Africa but transplanted to Tennessee, Jason has three passports and an ambiguous accent. Now living in San Francisco, he is an engineering manager at Strava, leading a team that focuses on the early stages of the new user lifecycle. Outside of work, Jason loves to cook and has aspirations of opening a restaurant some day.',
        linkedin: 'jason-van-der-merwe-28699332/',
        twitter: 'jasonvdmerwe',
    },
    {
        slug: 'lindsey',
        firstName: 'Lindsey',
        lastName: 'Wallace',
        imageAvatar: 'avatar-lindsey-wallace.jpg',
        imageBackground: 'bg-lindsey-wallace.jpg',
        imageCard: 'card-lindsey-2allace.jpg',
        imageCompany: 'brand-adobe.svg',
        headline: 'Design Research Manager - Enterprise and Teams',
        company: 'Adobe',
        bio: 'I bring knowledge about creative professionals\' practices, needs, and motivations to our Adobe design and product teams. As an anthropologist and systems thinker, I use my observation skills and experience understanding individuals in their broader contexts to explain user problems and provide insights into the cultures and values that shape users\' interactions and experiences.',
        twitter: 'LindseyWWallace',
        linkedin: 'lindseymwallace',
    },
    {
        slug: 'pietro',
        firstName: 'Pietro',
        lastName: 'Basso',
        imageAvatar: 'avatar-pietro-basso.jpg',
        imageBackground: 'bg-pietro-basso.jpg',
        imageCard: 'card-pietro-basso.jpg',
        imageCompany: 'brand-n26.svg',
        headline: 'iOS Engineer',
        company: 'N26',
        bio: 'Pietro is an iOS Engineer at N26, working on giving people the power to live and bank their way, changing the way banks are perceived, and used, in life and culture.',
        twitter: 'ppietrobasso',
        linkedin: 'bassop94/',
    },
    {
        slug: 'nicholas',
        firstName: 'Nicholas',
        lastName: 'Goubert',
        imageAvatar: 'avatar-nicholas-goubert.jpg',
        imageBackground: 'bg-nicholas-goubert.jpg',
        imageCard: 'card-nicholas-goubert.jpg',
        imageCompany: 'brand-clark.svg',
        headline: 'Chief Product & Technology Officer',
        company: 'CLARK',
        bio: 'Tech-enabled product leader, I get my kicks building teams and enabling them to design and deliver products our customers want and we can all be proud of.',
        linkedin: 'nicholasgoubert',
    },
    {
        slug: 'dominik',
        firstName: 'Dominik',
        lastName: 'Kenzler',
        imageAvatar: 'avatar-dominik-kenzler.jpg',
        imageBackground: 'bg-dominik-kenzler.jpg',
        imageCard: 'card-dominik-kenzler.jpg',
        imageCompany: 'brand-clark.svg',
        headline: 'SVP Product Design',
        company: 'CLARK',
        bio: 'Dominik is a product and design leader from Berlin. He focuses on building high-performance product teams, with a strong focus on design and product discovery. Currently, he leads the product design department at the InsureTech startup CLARK. Dominik is also into writing and is one of the co-authors of the bestselling book "Digital Innovation Playbook.',
        linkedin: 'dominik-kenzler-he-him-70175b85',
        twitter: 'dominikkenzler',
    },
    {
        slug: 'andy',
        firstName: 'Andy',
        lastName: 'Goodman',
        imageAvatar: 'avatar-andy-goodman.jpg',
        imageBackground: 'bg-andy-goodman.jpg',
        imageCard: 'card-andy-goodman.jpg',
        imageCompany: 'brand-pa.svg',
        headline: 'Design Partner',
        company: 'PA Consulting',
        bio: 'Andy is a pioneer of the Service Design industry. After an early career in web design, Interactive TV and games he moved to design consulting with the customer experience firm, Seren, now owned by EY. From 2008 he spent 8 years at Fjord in London, Madrid and New York overseeing large scale integrations with Accenture after the acquisition in 2013. He has since had spells at BCG and frog. Andy is a frequent speaker at global conferences and events including TED and SxSW, and is widely published on emerging technology and design.',
        linkedin: 'goodmanandy',
    },
    {
        slug: 'mischa',
        firstName: 'Mischa',
        lastName: 'Hildebrand',
        imageAvatar: 'avatar-mischa-hildebrand.jpg',
        imageBackground: 'bg-mischa-hildebrand.jpg',
        imageCard: 'card-mischa-hildebrand.jpg',
        imageCompany: 'brand-quickbird.svg',
        headline: 'iOS Developer',
        company: 'QuickBird Studios',
        bio: 'They call him “the Bluetooth-whisperer” at QuickBird Studios, Munich, where Mischa connects iPhones with medical devices to make life easier for people suffering from diabetes. In his earlier life, he led a team of mobile developers at intive, until he decided to travel the world for 7 months and reflect on the meaning of life and all that stuff. He got back just in time for lockdown. Now that’s timing!\n' +
            'Mischa has a background in physics and worked as a radio reporter for several years – an experience that provides him with a unique perspective on various aspects of app development. His favorite question: Why?',
        twitter: 'DerHildebrand/',
        facebook: 'mischa.hildebrand/',
        linkedin: 'mischa-hildebrand/',
    },
    {
        slug: 'lisa',
        firstName: 'Lisa',
        lastName: 'Dziuba',
        imageAvatar: 'avatar-lisa-dziuba.jpg',
        imageBackground: 'bg-lisa-dziuba.jpg',
        imageCard: 'card-lisa-dziuba.jpg',
        headline: 'Product Marketing Leader, ex-Head of Marketing at Abstract SDK',
        bio: 'I love creating and launching products. Built and led my tech startup to the acquisition (Flawless App, used by Uber, Spotify, Nike). Headed product marketing & community for Abstract SDK, a $54M funded company. Engineer by background, product marketer by craft.',
        twitter: 'LisaDziuba/',
        linkedin: 'lisa-dziuba/',
    },
    {
        slug: 'enrique',
        firstName: 'Enrique',
        lastName: 'López Mañas',
        imageAvatar: 'avatar-enrique-lopez-manas.jpg',
        imageBackground: 'bg-enrique-lopez-manas.jpg',
        imageCard: 'card-enrique-lopez-manas.jpg',
        headline: 'Google Developer Expert',
        company: 'Freelance',
        bio: 'Enrique López Mañas is a Google Developer Expert and independent IT consultant. He has been working with mobile technologies and learning from them since 2007. He is an avid contributor to the open source community and a FLOSS (Free Libre Open Source Software) kind of guy, being among the top 10 open source Java contributors in Germany. He is a part of the Google LaunchPad accelerator, where he participates in Google global initiatives to influence hundreds of the best startups from all around the globe. He is also a big data and machine learning aficionado. In his free time, he rides his bike, takes pictures, and travels until exhaustion. He also writes literature and enjoys all kinds of arts. He likes to write about himself in the third person.',
        linkedin: 'eenriquelopez',
        twitter: 'eenriquelopez',
    },
    {
        slug: 'joe',
        firstName: 'Joe',
        lastName: 'Birch',
        imageAvatar: 'avatar-joe-birch.jpg',
        imageBackground: 'bg-joe-birch.jpg',
        imageCard: 'card-joe-birch.jpg',
        imageCompany: 'brand-buffer.svg',
        headline: 'Senior Engineer',
        company: 'Buffer',
        bio: 'Hi, my names Joe. I’m an Android Engineer and Google Developer Expert for Android, Google Pay and Flutter based in Brighton, UK working on the Android team at Buffer. I’m passionate about coding and love creating robust, polished and exciting projects for mobile.',
        twitter: 'hitherejoe/',
        linkedin: 'joe-birch-80392157/',
    },
    {
        slug: 'roy',
        firstName: 'Roy',
        lastName: 'Marmelstein',
        imageAvatar: 'avatar-roy-marmelstein.jpg',
        imageBackground: 'bg-roy-marmelstein.jpg',
        imageCard: 'card-roy-marmelstein.jpg',
        imageCompany: 'brand-spotify.svg',
        headline: 'Host',
        company: 'ADDC',
        bio: 'Roy is a Lead iOS engineer at Spotify who is currently focused on Spotify\'s mobile design system and component library. He spoke at ADDC twice and really misses Barcelona.',
        linkedin: 'roy-marmelstein-2ba36527',
        twitter: 'marmelroy',
    },
];

export const getSpeakerBySlug: (slug: string) => Speaker|undefined = (slug) => {
    return speakers.filter((speaker) => speaker.slug === slug)[0];
};

export const getNextSpeakerAfter: (currentSpeaker: Speaker) => Speaker = (currentSpeaker) => {
    const index = speakers.indexOf(currentSpeaker);

    if (index === speakers.length - 1) {
        return speakers[0];
    }
    return speakers[index + 1];
};

export const getPreviousSpeakerBefore: (currentSpeaker: Speaker) => Speaker = (currentSpeaker) => {
    const index = speakers.indexOf(currentSpeaker);

    if (index === 0) {
        return speakers[speakers.length - 1];
    }
    return speakers[index - 1];
};

export default speakers;
