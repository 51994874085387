<template>
    <section class="section section-content section-sponsors">
        <div class="container grid-lg">

            <div class="sponsor-list">
                <p class="text-muted mb-0">
                    Our Sponsors
                </p>
                <div class="columns">
                    <div class="column col-4 col-sm-6">
                        <a href="https://www.shapr3d.com/" target="_blank">
                            <img class="img-responsive" src="../assets/img/sponsors/brand-shapr3d.svg">
                        </a>
                    </div>
                    <div class="column col-4 col-sm-6">
                        <a href="https://www.beams.fm/" target="_blank">
                            <img class="img-responsive" src="../assets/img/sponsors/brand-beams.svg">
                        </a>
                    </div>
                </div>
            </div>

            <div class="divider"></div>

            <div class="sponsorship">
                <div class="section-title h2">
                    <div class="h2">
                        <h3 class="h2"><strong>Become a sponsor</strong></h3>
                        <div class="subtitle">
                            All sponsorships are <span class="highlight"><strong>custom-structured</strong></span> so we can find something that best fits the personality of our event and your brand.
                        </div>

                        <div class="subtitle">
                            You can check out our <a href="https://addconf.com/2021/addc_draft_sponsorship.pdf" target="_blank">draft sponsorship deck here</a>.
                        </div>

                        <div class="subtitle">
                            We would love to see how we can work together.
                        </div>

                    </div>
                </div>
                <div>
                    <!-- <router-link to="/become-a-sponsor/" target="_blank" class="btn btn-primary">
                        Sponsorship packages
                    </router-link> -->
                    <a class="btn btn-primary btn-lg" href="mailto:info@addconf.com?subject=Sponsorship&nbsp;request" target="_blank" v-on:click.prevent="letsTalkSponsors()">
                        🤗 Let's talk
                    </a>
                </div>
            </div>


            

            

            <div class="divider divider-gradient"></div>

            <!-- <div class="sponsor-list">
                <p class="text-muted mb-0">
                    Our Gold sponsors
                </p>
                <div class="columns">
                    <div class="column col-4 col-sm-6">
                        <a href="https://www.teamwolfgang.com/" target="_blank">
                            <img class="img-responsive" src="../assets/img/sponsors/brand-wolfgang.svg">
                        </a>
                    </div>
                    <div class="column col-4 col-sm-6">
                        <a href="https://www.idshop.si/" target="_blank">
                            <img class="img-responsive" src="../assets/img/sponsors/brand-idshop.svg">
                        </a>
                    </div>
                    <div class="column col-4 col-sm-6">
                        <a href="https://www.edreamsodigeo.com/" target="_blank">
                            <img class="img-responsive" src="../assets/img/sponsors/brand-edreams.svg">
                        </a>
                    </div>
                </div>
            </div> -->

            <!-- <div class="sponsor-list">
                <p class="text-muted mb-0">
                    Our Patrons
                </p>
                <div class="columns">
                    <div class="column col-3 col-sm-4">
                        <a href="https://www.strava.com/" target="_blank">
                            <img class="img-responsive" src="../assets/img/sponsors/brand-strava.svg">
                        </a>
                    </div>
                    <div class="column col-3 col-sm-4">
                        <a href="https://deliveroo.co.uk/" target="_blank">
                            <img class="img-responsive" src="../assets/img/sponsors/brand-deliveroo.svg">
                        </a>
                    </div>
                    <div class="column col-3 col-sm-4">
                        <a href="https://wework.com/" target="_blank">
                            <img class="img-responsive" src="../assets/img/sponsors/brand-wework.svg">
                        </a>
                    </div>
                    <div class="column col-3 col-sm-4">
                        <a href="http://stickermule.com/supports/addc19" target="_blank">
                            <img class="img-responsive" src="../assets/img/sponsors/brand-stickermule.svg">
                        </a>
                    </div>
                </div>
            </div> -->

            <!-- <div class="sponsor-list">
                <p class="text-muted mb-0">
                    Our Media Partners
                </p>
                <div class="columns">
                    <div class="column col-3 col-sm-4">
                        <a href="https://www.sketchapp.com/" target="_blank">
                            <img class="img-responsive" src="../assets/img/sponsors/brand-sketch.svg">
                        </a>
                    </div>
                    <div class="column col-3 col-sm-4">
                        <a href="https://mobileera.rocks/" target="_blank">
                            <img class="img-responsive" src="../assets/img/sponsors/brand-mobileera.svg">
                        </a>
                    </div>
                    <div class="column col-3 col-sm-4">
                        <a href="https://digitalagencynetwork.com/" target="_blank">
                            <img class="img-responsive" src="../assets/img/sponsors/brand-dan.svg">
                        </a>
                    </div>
                    <div class="column col-3 col-sm-4">
                        <a href="https://www.techevents.online/" target="_blank">
                            <img class="img-responsive" src="../assets/img/sponsors/brand-tech-events.svg">
                        </a>
                    </div>
                </div>
            </div> -->

        </div>
    </section>
</template>

<script>
    export default {
        name: 'Sponsors',
        methods: {
            letsTalkSponsors() {
                Intercom('showNewMessage', `Hi, I am interested to become a sponsor.`);
            },
        },
    };
</script>

<style scoped>

</style>
