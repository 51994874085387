<template>
    <div class="page">

        <Menu />

        <NavigationBar back-link="/" back-text="Home"/>

        <section class="section section-content section-schedule">
            <div class="container grid-xl">

                <div class="section-title h2">
                    <h3 class="h2">
                        <strong>Schedule</strong>
                    </h3>
                    <div class="subtitle">
                        Two days of <span class="highlight">inspiring talks</span>.
                        <div>
                            <small class="text-muted">*The schedule is subject to change.</small>
                        </div>
                    </div>
                </div>
                <div class="divider divider-gradient"></div>

                <Schedule v-bind:schedule="schedule" showDayPartTitle />
            </div>
        </section>

        <Footer/>
    </div>
</template>
<script>
    import Schedule from '../components/Schedule.vue';
    import MetaData from '../mixins/MetaData.vue';
    import schedule from '../store/schedule';

    export default {
        mixins: [MetaData],
        components: {
            Schedule,
        },
        data() {
            return {
                schedule,
            };
        },

        computed: {
            seo() {
                return {
                    title: 'Schedule',
                    url: 'schedule/',
                };
            },
        },
    };
</script>
