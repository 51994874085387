<template>
    <div class="page">

        <Menu />

        <NavigationBar back-link="/" back-text="Home"/>

        <Accommodation/>

        <Footer/>
    </div>
</template>
<script>
    import Accommodation from '../components/Accommodation.vue';
    import MetaData from '../mixins/MetaData.vue';

    export default {
        mixins: [MetaData],
        components: {
            Accommodation,
        },
        computed: {
            seo() {
                return {
                    title: 'Accommodation',
                    url: 'accommodation/',
                };
            },
        },
    };
</script>
