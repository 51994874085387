<template>
    <div class="page speaker">

        <Menu />

        <NavigationBar back-link="/speakers" back-text="Speakers"/>

        <section class="section section-speaker section-inverted" v-if="speaker" v-bind:style="backgroundImage">

            <div></div>
            <div>
                <div class="speaker-content">
                    <div class="container grid-lg">
                        <div class="columns">
                            <div class="column col-5 col-md-12">
                                <div class="section-title h2">
                                    <h1 class="h2">
                                        <strong>{{ speaker.firstName }}</strong> {{ speaker.lastName }}
                                    </h1>
                                    <div class="subtitle">
                                        － {{ speaker.headline }} <span v-if="speaker.company">at <strong>{{ speaker.company }}</strong></span>
                                    </div>
                                </div>
                                <div class="social">
                                    <a v-bind:href="`https://www.linkedin.com/in/${speaker.linkedin}`" v-if="speaker.linkedin" target="_blank" rel="noopener">
                                        <img class="social-linkedin" src="../assets/img/social-linkedin.svg" alt="LinkedIn" />
                                    </a>
                                    <a v-bind:href="`https://twitter.com/${speaker.twitter}`" target="_blank" v-if="speaker.twitter" rel="noopener">
                                        <img class="social-twitter" src="../assets/img/social-twitter.svg" alt="Twitter" />
                                    </a>
                                    <a v-bind:href="`https://www.facebook.com/${speaker.facebook}`" v-if="speaker.facebook" target="_blank" rel="noopener">
                                        <img class="social-facebook" src="../assets/img/social-facebook.svg" alt="Facebook" />
                                    </a>
                                </div>
                                <div class="speaker-details">
                                    <label class="text-muted">
                                        About
                                    </label>
                                    <p class="speakerBio">{{ speaker.bio }}</p>


                                    <div v-if="talk" class="speakerDetailsSegment">
                                        <label class="text-muted">
                                            Talk
                                        </label>
                                        <p>
                                            <strong>
                                                {{ talk.title }}
                                            </strong>
                                        </p>
                                        <router-link class="btn btn-primary" v-bind:to="`/schedule/${talk.slug}/`" v-bind:title="`${talk.title}`">View talk</router-link>
                                    </div>
                                    <div v-if="workshop" class="speakerDetailsSegment">
                                        <label class="text-muted">
                                            Workshop
                                        </label>
                                        <p>
                                            <strong>
                                                {{ workshop.title }}
                                            </strong>
                                        </p>
                                        <router-link class="btn btn-primary" v-bind:to="`/schedule/${workshop.slug}/`" v-bind:title="`${workshop.title}`">View workshop</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section>

        <section class="section section-speaker-nav">
            <div class="container grid-lg">

                <div class="divider divider-gradient mt-0 show-md"></div>

                <div class="split-nav">
                    <router-link v-bind:to="`/speakers/${previousSpeaker.slug}/`" v-if="speakers.length > 1" v-bind:title="`${previousSpeaker.firstName} ${previousSpeaker.lastName}`">
                        <div class="tile tile-centered">
                            <i class="icon icon-arrow-left"></i>
                            <div class="tile-icon">
                                <img class="avatar avatar-lg" :src="pathForImage(previousSpeaker.imageAvatar)">
                            </div>
                            <!-- <div class="tile-icon">
                                <div class="diamond">
                                    <img src="../assets/img/speakers/donald.jpg" />
                                </div>
                            </div> -->
                            <div class="tile-content hide-sm">
                                <strong>{{ previousSpeaker.firstName }}</strong> {{ previousSpeaker.lastName }}
                                <div>
                                    <small class="text-muted">
                                        {{ previousSpeaker.company }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </router-link>
                    <router-link v-bind:to="`/speakers/${nextSpeaker.slug}/`" v-if="speakers.length > 1" v-bind:title="`${nextSpeaker.firstName} ${nextSpeaker.lastName}`">
                        <div class="tile tile-centered">
                            <div class="tile-content hide-sm">
                                <strong>{{ nextSpeaker.firstName }}</strong> {{ nextSpeaker.lastName }}
                                <div>
                                    <small class="text-muted">
                                        {{ nextSpeaker.company }}
                                    </small>
                                </div>
                            </div>
                            <!-- <div class="tile-icon">
                                <div class="diamond">
                                    <img src="../assets/img/speakers/goofy.jpg" />
                                </div>
                            </div> -->
                            <div class="tile-icon">
                                <img class="avatar avatar-lg" :src="pathForImage(nextSpeaker.imageAvatar)">
                            </div>
                            <i class="icon icon-arrow-right"></i>
                        </div>
                    </router-link>
                </div>

            </div>
        </section>

    </div>

</template>
<style>
    .speakerBio {
        white-space: pre-wrap;
    }
    .speakerDetailsSegment {
        margin-bottom: 1.2rem;
    }

    .speakerDetailsSegment:last-child {
        margin-bottom: 0;
    }
</style>
<script>
    import MetaData from '../mixins/MetaData.vue';
    import speakers, { getSpeakerBySlug, getPreviousSpeakerBefore, getNextSpeakerAfter } from '../store/speakers';
    import { getTalkBySpeakerSlug } from '../store/talks';
    import { getWorkshopBySpeakerSlug } from '../store/workshops';

    export default {
        mixins: [MetaData],
        mounted() {
            if (!this.speaker) {
                this.$router.push('/speakers/');
            }
        },
        methods: {
            pathForImage(imageName) {
                console.log(imageName);
                return require(`../assets/img/speakers/${imageName}`);
            },
        },
        computed: {
            seo() {
                return {
                    title: `${this.speaker.firstName} ${this.speaker.lastName}`,
                    description: this.speaker.bio,
                    url: `speaker/${this.$route.params.slug}/`,
                    image: `cards/${this.speaker.imageCard}`,
                    imageWidth: '1024',
                    imageHeight: '580',
                };
            },
            speakers() {
                return speakers;
            },
            speaker() {
                return getSpeakerBySlug(this.$route.params.slug);
            },
            nextSpeaker() {
                return getNextSpeakerAfter(this.speaker);
            },
            previousSpeaker() {
                return getPreviousSpeakerBefore(this.speaker);
            },
            talk() {
                return getTalkBySpeakerSlug(this.$route.params.slug);
            },
            workshop() {
                return getWorkshopBySpeakerSlug(this.$route.params.slug);
            },
            backgroundImage() {
                const image = this.speaker.imageBackground;
                const path = this.pathForImage(image);
                return {
                    'background-image': `url('${path}' !important`,
                };
            },
        },
    };
</script>
