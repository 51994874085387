<template>
    <div class="page">

        <Menu />

        <NavigationBar back-link="/" back-text="Home"/>

        <section class="section section-content section-schedule">
            <div class="container grid-xl">

                <div class="section-title h2">
                    <h3 class="h2">
                        <strong>Workshops</strong>
                    </h3>
                    <div class="subtitle">
                        Thursday, June 27th<br/>
                         <span class="highlight">Workshop ticket required</span>
                    </div>
                </div>

                <Schedule v-bind:schedule="schedule" />
            </div>
        </section>

        <Footer/>
    </div>
</template>
<script>
    import Schedule from '../components/Schedule.vue';
    import MetaData from '../mixins/MetaData.vue';
    import { getScheduleWithDay } from '../store/schedule';

    export default {
        mixins: [MetaData],
        components: {
            Schedule,
        },
        data() {
            return {
                schedule: getScheduleWithDay('workshops'),
            };
        },

        computed: {
            seo() {
                return {
                    title: 'Workshops',
                    url: 'workshops/',
                };
            },
        },
    };
</script>
