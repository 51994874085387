<template>
    <section class="section section-content section-tickets" id="register">
        <div class="container grid-lg">

            <div class="tickets">
                <div class="columns">
                    <div class="column col-6 col-sm-12 col-md-8 col-mx-auto">
                        <div class="card">
                            <div class="card-header text-center">
                                <div class="artwork-emoticon">
                                    🎟
                                </div>
                                <div class="section-title h3">
                                    <h3 class="h3">
                                        <strong>Tickets</strong>
                                    </h3>
                                    <div class="subtitle">
                                        Join us for our <span class="highlight">remote edition</span>
                                    </div>
                                </div>
                                <a class="btn btn-primary btn-lg mb-2" href="https://ti.to/addc/2021" target="_blank">🎟 Get your ticket</a>
                                <div class="subtitle tickets-discount">
                                    <span class="text-muted">Get a <strong>10% group discount</strong> for 5+ tickets</span> <a href="https://ti.to/addc/2021/discount/group" target="_blank">here</a>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                studentDiscountModalShown: false,
                groupDiscountModalShown: false,
                appliedDiscount: this.$route.query.discount || null,
                buyButtonText: 'Register now',
                studentDiscountCode: 'STUDENT',
                groupDiscountCode: 'GROUP',
                basePrice: 549,
                workshopPrice: 99,
                workshopActivated: false,
                earlyBirdDiscount: 'EARLYBIRD',
                earlyBirdDiscountAmount: 150,
                earlyBirdDiscountIsActive: false,
            };
        },
        mounted() {
            this.reloadButton();
        },
        updated() {
            this.reloadButton();
        },
        computed: {
            minimumPurchaseQuantity() {
                switch (this.appliedDiscount) {
                    case this.groupDiscountCode: return 5;
                    default: return 1;
                }
            },

            ticketRelease() {
                if (this.workshopActivated) {
                    if (this.earlyBirdDiscountIsActive) {
                        return 'fkvykk8evpi';
                    } else {
                        return 'mxjhvudhvy0,j01rakqsfoy,synpazgdils,fo7pkjvjots';
                    }
                } else {
                    if (this.earlyBirdDiscountIsActive) {
                        return '1h0rn1jeygc';
                    } else {
                        return 'tjnq32or5es';
                    }
                }
            },

            discountTitle() {
                switch (this.appliedDiscount) {
                    case this.studentDiscountCode: return 'STUDENT';
                    case this.groupDiscountCode: return 'GROUP';
                    case 'SANJOSE': return 'SANJOSE DISCOUNT';
                    default: return this.earlyBirdDiscountIsActive ? this.earlyBirdDiscount : null;
                }
            },
            priceWithoutDiscount() {
                let price = this.basePrice;

                if (this.workshopActivated) {
                    price += this.workshopPrice;
                }

                return price;
            },
            price() {
                const price = this.priceWithoutDiscount;

                switch (this.appliedDiscount) {
                    case this.studentDiscountCode: return price - 150;
                    case this.groupDiscountCode: return (price * 0.9).toFixed(2).replace('.', ',');
                    case 'SANJOSE': return price - 90;
                    default: return this.earlyBirdDiscountIsActive ? (price - this.earlyBirdDiscountAmount) : price;
                }
            },
        },
        methods: {
            reloadButton() {
                if (!this.$refs.buyButton) {
                    return;
                }
                this.$refs.buyButton.innerHTML = this.buyButtonText;

                if (window.ga) {
                    window.ga(() => {
                        const tracker = window.ga.getByName('addcGATracker');
                        if (typeof tracker !== 'undefined') {
                            const linkerParam = tracker.get('linkerParam');
                            window.TitoWidget.additional_url_params = `&${linkerParam}`;
                        } else {
                            console.error('Missing tracker with name addcGATracker!');
                        }

                        this.reloadTito();
                    });
                } else {
                    console.error('Missing Google Analytics ga variable!');
                    this.reloadTito();
                }
            },

            reloadTito() {
                if (typeof window.TitoWidget !== 'undefined') {
                    TitoWidget.buildWidgets();
                    console.log('TITO buttton Built immediately');
                } else {
                    window.titoWidgetCallback = () => {
                        TitoWidget.build_widgets = true;
                        console.log('TITO buttton will be build by the SDK');
                    };
                }
            },

        },
    };
</script>
<style>
    #workshop-row {
        cursor: pointer;
    }
    section .modal {
        position: fixed !important;
    }

    tito-button {
        cursor: pointer;
    }

    tito-button button {
        cursor: pointer;
        background-color: inherit;
        border: inherit;
        color: inherit;
    }
</style>
