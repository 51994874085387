<template>
    <section class="section section-content section-testimonials">
        <div class="container grid-lg">
            <div class="testimonials">
                <div id="trustpilot">
                    <div id="trustpilot-widget" class="trustpilot-widget" data-locale="en-US" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="5b4378c3470b9400016947bd" data-style-height="240px" data-style-width="100%" data-theme="dark" data-stars="5" data-schema-type="Organization">
                        <a href="https://www.trustpilot.com/review/addconf.com" target="_blank">Trustpilot</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'TrustPilot',
        mounted() {
            if (typeof window.Trustpilot !== 'undefined') {
                window.Trustpilot.loadFromElement(document.getElementById('trustpilot-widget'));
                console.log('Trust pilot loaded immediately');
            } else {
                window.trustpilotCallback = () => {
                    window.Trustpilot.loadFromElement(document.getElementById('trustpilot-widget'));
                    console.log('Trust pilot loaded after callback');
                };
            }
        },
    };
</script>

<style scoped>

</style>
