<template>
    <div class="page page-sponsors">

        <Menu />
        <NavigationBar back-link="/" back-text="Home"/>

        <App/>

        <Footer/>
    </div>
</template>

<script>
    import EventStructuredData from '../components/EventStructuredData';
    import SubscribeNewsletter from '../components/SubscribeNewsletter';
    import Accommodation from '../components/Accommodation.vue';
    import Sponsors from '../components/Sponsors';
    import Venue from '../components/Venue.vue';
    import ScheduleHome from '../components/ScheduleHome.vue';
    import Speakers from '../components/Speakers.vue';
    import TrustPilot from '../components/TrustPilot';
    import MetaData from '../mixins/MetaData.vue';
    import Social from '../mixins/Social.vue';
    import Activities from '../components/Activities.vue';
    import ActivitiesSuccess from '../components/ActivitiesSuccess.vue';
    import ActivitiesError from '../components/ActivitiesError.vue';
    import Party from '../components/Party.vue';
    import App from '../components/App.vue';

    export default {
        mixins: [MetaData, Social],
        components: {
            TrustPilot,
            Speakers,
            ScheduleHome,
            Venue,
            Sponsors,
            Accommodation,
            SubscribeNewsletter,
            EventStructuredData,
            Activities,
            ActivitiesSuccess,
            ActivitiesError,
            Party,
            App,
        },
    };
</script>
