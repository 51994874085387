<script>
    export default {
        beforeUpdate() {
            this.socialUpdate();
        },
        mounted() {
            this.socialUpdate();
        },
        methods: {
            socialUpdate() {
                if (window.twttr) {
                    window.twttr.widgets.load();
                }

                if (window.FB) {
                    window.FB.XFBML.parse();
                } else {
                    window.fbAsyncInit = () => {
                        window.FB.XFBML.parse();
                    };
                }
            },
        },
    };
</script>
