<template>
    <div class="page page-sponsors">

        <Menu />

        <NavigationBar back-link="/" back-text="Home"/>

        <Activities/>

        <Footer/>
    </div>
</template>
<script>
    import MetaData from '../mixins/MetaData.vue';
    import Activities from '../components/Activities';

    export default {
        mixins: [MetaData],
        components: {
            Activities,
        },
        computed: {
            seo() {
                return {
                    title: 'Activities',
                    url: 'activities/',
                };
            },
        },
    };
</script>
