<template>
    <div class="page page-talk">

        <Menu />

        <NavigationBar back-link="/" back-text="Home"/>

        <section class="section section-content">
            <div class="container grid-md">

                <div class="section-title h2 text-center">
                    <h2 class="h1">
                        <strong>Talks at ADDC 2021</strong>
                    </h2>
                    <div class="subtitle">
                        Watch free recordings of the talks presented at the ADDC 2021
                    </div>
                </div>

                <div class="divider divider-gradient"></div>

                <div v-for="talk in talks">

                    <div class="talk-list-item">
                        <div class="columns">

                            <div class="column col-4 col-sm-12 thumbnail-link">
                                <router-link :to="`/schedule/${talk.slug}`">
                                    <img class="talk-thumbnail" v-bind:src="pathForImage(`talks/${talk.slug}.jpg`)" />
                                </router-link>
                            </div>

                            <div class="column col-8 col-sm-12">
                                <h4>
                                    <router-link :to="`/schedule/${talk.slug}`">
                                        {{ talk.title }}
                                    </router-link>
                                </h4>
                                <div class="speaker-nav">
                                    <div class="" v-for="speaker in talk.speakers">
                                        <div class="tile tile-centered">
                                            <div class="tile-icon">
                                                <img class="avatar avatar-lg" v-bind:src="pathForImage(`speakers/${speaker.imageAvatar}`)" />
                                            </div>
                                            <div class="tile-content">
                                                <strong>{{ speaker.firstName }}</strong> {{ speaker.lastName }}
                                                <div>
                                                    <small class="text-muted">
                                                        {{ speaker.company }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </section>

        <Footer/>
    </div>
</template>
<script>
    import MetaData from '../mixins/MetaData.vue';
    import talks from '../store/talks';
    import { getSpeakerBySlug } from '../store/speakers';

    export default {
        mixins: [MetaData],
        data() {
            return {
                talks: talks.map((talk) => {
                    const mappedTalk = talk;
                    mappedTalk.speakers = talk.speakersSlugs.map((slug) => getSpeakerBySlug(slug));
                    return mappedTalk;
                }),
            };
        },
        computed: {
            seo() {
                return {
                    title: 'Talks',
                    url: 'talks/',
                    image: 'share-talks.jpg',
                };
            },
        },
        methods: {
            pathForImage(imageName) {
                return require(`../assets/img/${imageName}.jpg`);
            },
        },
    };
</script>
