<template>
    <a v-on:click.prevent="didClick()" href="#"><slot></slot></a>
</template>

<script>
    export default {
        methods: {
            didClick: () => {
                window.Intercom('showNewMessage');
            },
        },
    };
</script>
