<template>
    <div class="page page-venue">

        <Menu />

        <NavigationBar back-link="/" back-text="Home"/>

        <Venue/>

        <Footer/>
    </div>
</template>
<script>
    import Venue from '../components/Venue.vue';
    import MetaData from '../mixins/MetaData.vue';

    export default {
        mixins: [MetaData],
        components: {
            Venue,
        },
        computed: {
            seo() {
                return {
                    title: 'Venue',
                    url: 'venue/',
                };
            },
        },
    };
</script>
