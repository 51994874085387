<template>
    <div class="page page-sponsors">

        <Menu />

        <NavigationBar back-link="/" back-text="Home"/>

        <Sponsors/>

        <Footer/>
    </div>
</template>
<script>
    import Sponsors from '../components/Sponsors.vue';
    import MetaData from '../mixins/MetaData.vue';

    export default {
        mixins: [MetaData],
        components: {
            Sponsors,
        },
        computed: {
            seo() {
                return {
                    title: 'Sponsors',
                    url: 'sponsors/',
                };
            },
        },
    };
</script>
