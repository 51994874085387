<template>
    <form v-on:submit.prevent="onSubmit">
        <div class="input-group">
            <input class="form-input input-lg" type="text" v-bind:placeholder="placeholder" v-model="email" >
            <button class="btn btn-primary input-group-btn btn-lg" v-bind:class="{ loading: isLoading }">
                <i class="icon" v-bind:class="{ 'icon-paper-plane': !isLoading && !isComplete, 'icon-check': !isLoading && isComplete }"></i>
            </button>
        </div>
    </form>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                isLoading: false,
                isComplete: false,
                email: '',
            };
        },
        computed: {
            placeholder() {
                if (this.isLoading) {
                    return 'Subscribing ...';
                } else if (this.isComplete) {
                    return 'Subscribed!';
                } else {
                    return 'Email';
                }
            },
        },
        methods: {
            onSubmit() {
                this.isLoading = true;
                const email = this.email;
                this.email = '';
                axios.post(`https://ve1t4d92w4.execute-api.eu-central-1.amazonaws.com/production/subscribe`, { email })
                    .then((response) => {
                        this.isLoading = false;
                        this.isComplete = true;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        this.email = email;
                        console.error(error);
                    });
            },
        },
    };
</script>
