<template>
    <section class="section section-content section-activities">
        <div class="container grid-lg">

            <div class="">
                <div class="section-title h2">
                    <h3 class="h2">
                        <strong>Success</strong>
                    </h3>
                    <div class="subtitle">
                        You have successfully reserved your spot at ADDC 2021 activity:
                        <div>
                            <small class="text-muted">
                                *Check your e-mail for further instructions.
                            </small>
                        </div>
                    </div>
                </div>
                <div class="activity-list">
                    <div class="columns grid-center">
                        <div class="column col-4 col-sm-12">
                            <div class="card">
                                <div class="card-image">
                                    <img class="img-responsive" src="../assets/img/activities/activity-art.jpg" alt="Streat Art Tour" />
                                </div>
                                <div class="card-header">
                                    <div class="card-title h6">
                                        <strong>
                                            Barcelona Street Art Walking Tour
                                        </strong>
                                        <div class="card-subtitle text-gray">
                                            Following the end of Franco’s dictatorship, rebellion and freedom of expression were firmly embraced in Spain, and creatives continue to be inspired by the protest culture and embracing their civil liberties, resulting in Barcelona's thriving street art scene acknowledged recently as one of the top 10 graffiti art cities world wide. During the tour we will explore the legal and illegal sides of street art, visit what have become murals, skate parks and galleries as well as stopping to take plenty of photos along the way.
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body text-gray text-muted">
                                    Duration: 1.5 hours
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'Accommodation',
    };
</script>

<style scoped>

</style>
