export interface Talk {
    slug: string;
    title: string;
    description: string;
    speakersSlugs: string[];
    slides?: string;
    youtube?: string;
    thumbnail?: string;
}

const talks: Talk[] = [
    {
        slug: 'swiftui-the-teen-years',
        title: 'SwiftUI and Combine - the Teen Years',
        description: 'In 2019 Apple released the SwiftUI and Combine frameworks as the future of development on iOS, macOS, and other Apple platforms. These frameworks were newborns. We cooed over them and saw the promise of the future but there were important pieces missing both in the frameworks and in our understanding of them. A few weeks ago at this year\'s WWDC we saw the second major update to SwiftUI and some useful additions to Combine. This talk will illustrate how to best to think about and use the latest in SwiftUI and Combine in your current and future apps.',
        speakersSlugs: [
            'daniel',
        ],
    },
    {
        slug: 'algorithmically-backed-discovery-feed',
        title: 'How to design, build and launch a new navigation pattern at scale',
        description: 'Strava is releasing a new navigation layout for our mobile apps that better serves athletes and scales with our ever expanding product. In this talk, I\'ll tell the story of how we set about researching, designing and building a navigation system that complements your athletic life. I\'ll talk about the challenges of rolling out a substantial change to 80MM athletes, and the tooling and processes we built to help us get there.',
        speakersSlugs: [
            'jason',
        ],
    },
    {
        slug: 'snapshot-testing',
        title: 'Testing UI Automatically with Snapshot Testing',
        description: 'The talk focuses on an open source iOS Framework to automatically test UI in an iOS codebase. The framework is basically a glorified “pixel-comparison” tool that generates PNG snapshots for all the tested UI and notifies the developer if the UI changed unexpectedly. It\'s a cool inspirational topic for both iOS Engineers, Android Engineers and Designers. For Designers: having a PNG representation of the whole application helps the team understanding how the app looks without having to build or run any device simulator. PNG snapshots of the app UI can be easily exported and shared between engineers and designers, to make sure that the company design system is respected as much as possible. For Engineers: it’s a great way to validate UI easily (3 lines of code for the whole test) and in a fast reliable way. Integrating this tool in an existing iOS codebase it’s easy and can be done incrementally. It encourages better isolation in the View layer and in the codebase in general, through dependency injection, in order to avoid flaky snapshot tests.',
        speakersSlugs: [
            'pietro',
        ],
    },
    {
        slug: 'multiplatform-design',
        title: 'Multiplatform Design: User reactions to non-native UI',
        description: 'Let\'s put our UX hats on and wrestle with the 1. tried and true versus the 2. fun and new. Not only do humans spend countless hours on their phones internalizing platform UI/UX nuances whether they want to or not, but we also spend hours in-app, experiencing and internalizing nested nuances. So, do modern users of 2020+ still need native design elements to confidently complete in-app tasks? Armed with guerilla usability tests and UX research, let\'s tackle this question that polarizes and effects us all.',
        speakersSlugs: [
            'frances',
        ],
    },
    {
        slug: 'your-feedback-sucks',
        title: 'Your Feedback Sucks!',
        description: 'Reviews are an essential part in the process of improving both our products and ourselves. Their importance cannot be overrated – yet, there is very little guidance available on how to do reviews properly. For mobile developers, code reviews are usually part of their job – a delicate one, because many things can go wrong. It\'s easy to fall for some typical behavior patterns that drastically reduce the value of the entire reviewing process. For designers, reviews are equally important in order to get valuable feedback. There is always a technical and a social component involved. If we want to get most of our review process, we must get both things right.',
        speakersSlugs: [
            'mischa',
        ],
    },
    {
        slug: 'desktop-class-experience-ipados',
        title: 'Delivering a desktop-class experience on iPadOS',
        description: 'It\'s been a year since Apple unveiled the Magic Keyboard for iPad, and doubled down on bringing a truly desktop-class experience to iPadOS, with advanced keyboard and mouse support. At Shapr3D we\'ve spent the last year exploring these new capabilities in our iPad CAD system, and we\'re eager to share with You the tips & tricks we\'ve learned along the way!',
        speakersSlugs: [
            'tamas',
        ],
    },
    {
        slug: 'discussing-research-driven-redesign',
        title: 'Overcoming a designer\'s fear of ambiguity: Discussing a research-driven redesign',
        description: 'Whether from testing designs or interviewing users, the ambiguity that arises from user research can seem intimidating for designers, which all too often makes us avoid it. It\'s a common experience for designers and design teams to have aspirations of being more research-driven, yet lack the confidence to make such an important shift. In the last couple of years at Wallapop, we began to place a higher emphasis on research, which meant our design team also became a research team. In this talk I will present a project of ours from last year - redesigning the app\'s navigation - and explain how during this project I went through a crash course in user research, fundamentally changing my approach to design. This talk is for designers who want to move towards not just doing more user research, but reframing themselves and their team as genuinely user focused.',
        speakersSlugs: [
            'erika',
        ],
    },
    {
        slug: 'android-animations',
        title: 'Android animations in 2021',
        description: 'Let\'s be honest. Animations in your app are probably the last thing to be implemented -if it will be implemented at all. That was fine till a few years ago, but now this is not the case anymore. The users expect some meaningful feedback about their actions, that will not interfere though with the flow of their interaction and it will be "normal" to the eye. Getting started though can be intimidating and selecting the right API for each task requires some research in the beginning. In this talk, we will compare all the APIs to each other and check where each one of them should be used.',
        speakersSlugs: [
            'eliza',
        ],
    },
    {
        slug: 'making-the-right-call',
        title: 'Making the Right Call: How to decide on and get buy-in for product rebuilds',
        description: 'Product teams often face moments at which we need to decide on a large product rebuilds or redesigns. Drawing on my experience across organizations from large (Oscar Health, 1K+ employees, IPO\'d) to startup (Cara Care, Beams), I will explain our product decision from the inception of the idea to rebuild a major part of the product, to getting buy-in from various stakeholders, to making the right call. This talk will focus on the variables and frameworks for making these decisions, and help developers and designers understand how they can best provide input for decisions like this in their teams while taking into account other stakeholders\' points of views that may influence their decisions.',
        speakersSlugs: [
            'tonia',
        ],
    },
    {
        slug: 'practical-security-for-android-developers',
        title: 'Practical security for Android developers',
        description: 'It is not unfrequent to neglect some of the most common security aspects in Android, and rely on the default solutions already provided by Android. Do we need to do perform any special action to protect our local databases? Is our connection secure when we use the standard Retrofit/OkHttp Stack? What are the risks when we are developing our apps with the proposed Google architecture, without any extra measures? In this session you will learn some of the frequently disregarded aspects in Android security, and how you can easily make your app better, stronger and more secure.',
        speakersSlugs: [
            'enrique',
        ],
    },
    {
        slug: 'everything-is-a-product',
        title: 'Everything is a product',
        description: 'We strongly believe that great products are crafted by a strong team led by a product creation trio made of a product manager, a product designer and an engineering lead. We are looking forward to sharing some learnings and best practices about how to nurture a great product creation environment.',
        speakersSlugs: [
            'dominik',
            'nicholas',
        ],
    },
    {
        slug: 'textual-healing',
        title: 'Textual Healing',
        description: 'Healthcare Apps have to be the success story of the pandemic during 2020. In this talk I will explore the unstoppable rise of CBT and other mental health therapies delivered through devices, how they can be a boon as well as some of the issues they raise.',
        speakersSlugs: [
            'andy',
        ],
    },
    {
        slug: 'ux-research-for-innovation',
        title: 'Saying the Quiet Parts Loud: UX Research for Innovation',
        description: 'Developing a new product or service is full of choices and tradeoffs regarding who to design for, problems to solve, and best solutions. Identifying opportunities and innovations that are good for business and people requires research to expand to bigger questions than “can the target user understand the product?” We need to ask: Who’s voices are we listening to in product and service design? Where are the silences, absences, and gaps in our perspective that might help us understand and mitigate potential unseen harms and risks and find hidden opportunities? This talk discusses the impact of “saying the quiet parts loud” with examples from enterprise and b-2-b research and provides you with questions to ask during your process and tools to conduct research that encompasses exclusion.',
        speakersSlugs: [
            'lindsey',
        ],
    },
    {
        slug: 'adopting-jetpack-compose',
        title: 'Adopting Jetpack Compose',
        description: 'With Jetpack Compose soon becoming stable, we\'ll soon be able to change the way that we build the UI of our apps. But what about our existing apps, where does Jetpack Compose fit in there? In this talk we\'ll explore how we can use Jetpack Compose in our existing Android classes, allowing you to incrementally adopt compose into existing applications and start using it in your projects right away!',
        speakersSlugs: [
            'joe',
        ],
    },
    {
        slug: 'your-first-steps-with-async-await',
        title: 'Your first steps with async / await',
        description: '',
        speakersSlugs: [
            'vincent',
        ],
    },
    {
        slug: 'hypothesis-driven-approach',
        title: 'From an idea to a product validation: hypothesis-driven approach',
        description: 'We all work with designing, developing, and launching products and we usually own one part of the puzzle. But what would be the process if you came up with a great product idea and want to launch it from scratch? In this talk you will learn hypothesis-driven product development, research, and validation techniques to create a product users love. You will get a practical step-by-step framework for making your side project live, suitable for every team (even a team of one).',
        speakersSlugs: [
            'lisa',
        ],
    },
];

export const getTalkBySpeakerSlug: (slug: string) => Talk|undefined = (slug) => {
    return talks.filter((talk) => talk.speakersSlugs.includes(slug))[0];
};

export const getTalkBySlug: (slug: string) => Talk|undefined = (slug) => {
    return talks.filter((talk) => talk.slug === slug)[0];
};

export default talks;
