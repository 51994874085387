export interface ConferenceDay {
    id: string;
    title: string;
    dateTitle: string;
    summary?: string;
    slots: TimeSlot[];
    highlight?: string;
    startTime?: number;
}

export interface TimeSlot {
    duration?: number;
    talkSlug?: string|null;
    speakerSlug?: string|null;
    workshopSlug?: string|null;
    title?: string;
    description?: string;
    description2?: string;
    url?: string;
    timeTitle?: string;
}


const rawSchedule: ConferenceDay[] = [
    {
        id: 'day1',
        title: 'Conference - Day 1',
        dateTitle: 'Wednesday - June 23',
        highlight: 'All times are in CEST (UTC+2) time zone',
        startTime: 780,
        slots: [
                { title: '🚪 Conference doors open - grab your coffee & check your audio settings', duration: 45 },
                { title: '🤗 Welcome huddle', duration: 15 },
                { talkSlug: 'snapshot-testing', duration: 45 },
                { talkSlug: 'everything-is-a-product', duration: 45 },
                { talkSlug: 'desktop-class-experience-ipados', duration: 45 },
                { talkSlug: 'hypothesis-driven-approach', duration: 45 },
                { talkSlug: 'your-first-steps-with-async-await', duration: 45 },
                { title: 'Break // Connect 🍽🤗', duration: 45 },
                { talkSlug: 'textual-healing', duration: 45 },
                { talkSlug: 'discussing-research-driven-redesign', duration: 45 },
                { talkSlug: 'android-animations', duration: 45 },
                { talkSlug: 'ux-research-for-innovation', duration: 45 },
                { title: '👏 Conference day ends', duration: 0 },
        ],
    },
    {
        id: 'day2',
        title: 'Conference - Day 2',
        dateTitle: 'Thursday - June 24',
        highlight: 'All times are in CEST (UTC+2) time zone',
        startTime: 780,
        slots: [
            { title: '🚪 Conference doors open - grab your coffee & check your audio settings', duration: 45 },
            { title: '🤗 Welcome huddle', duration: 15 },
            { talkSlug: 'your-feedback-sucks', duration: 45 },
            { talkSlug: 'adopting-jetpack-compose', duration: 45 },
            { talkSlug: 'making-the-right-call', duration: 45 },
            { talkSlug: 'practical-security-for-android-developers', duration: 45 },
            { title: 'Break // Connect 🍽🤗', duration: 45 },
            { talkSlug: 'multiplatform-design', duration: 45 },
            { talkSlug: 'swiftui-the-teen-years', duration: 45 },
            { talkSlug: 'algorithmically-backed-discovery-feed', duration: 45 },
            { title: '👏 Conference day ends', duration: 0 },
        ],
    },
];

function twoDigits(val: number) {
    return (('0' + val).slice(-2));
}

function timeStringFromTimestamp(timestamp: number): string {
    const hours = Math.floor(timestamp / 60);
    const minutes = (timestamp % 60);
    return `${twoDigits(hours)}:${twoDigits(minutes)}`;
}

const schedule = rawSchedule.map((line: ConferenceDay) => {
    if (!line.startTime) {
        return line;
    }

    const mappedLine = line;
    let currentTime = line.startTime;
    mappedLine.slots = line.slots.map((slot: TimeSlot) => {
        const mappedSlot = slot;
        mappedSlot.timeTitle = timeStringFromTimestamp(currentTime);
        currentTime += mappedSlot.duration || 0;
        return slot;
    });
    return mappedLine;
});

export const getScheduleWithDay: (dayID: string) => ConferenceDay[] = (filterDayID) => {
    return schedule.filter((day: ConferenceDay) => day.id === filterDayID);
};

export default schedule;
