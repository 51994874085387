<template>
    <section class="section section-content section-accommodation">
        <div class="container grid-lg">

            <div class="">
                <div class="section-title h3">
                    <h3 class="h3">
                        <strong>Accommodation</strong>
                    </h3>
                    <div class="subtitle">
                        There are plenty hotels to choose from near ADDC venue. If you are looking for nearby options, these are as close as it gets:
                    </div>
                </div>
                <div class="accommodation-list">
                    <div class="columns">
                        <div class="column col-3 col-sm-6">
                            <a class="card" href="http://www.booking.com/hotel/es/hilton-barcelona.html?aid=1476163&checkin_monthday=25&checkin_month=6&checkin_year=2019&checkout_monthday=29&checkout_month=6&checkout_year=2019&no_rooms=1&group_adults=1&room1=A&sb_travel_purpose=business" target="_blank">
                                <div class="card-image">
                                    <img class="img-responsive" src="../assets/img/accommodation-1.jpg">
                                </div>
                                <div class="card-header">
                                    <div class="card-title h6">
                                        <strong>
                                            Hilton Barcelona
                                        </strong>
                                        <div class="card-subtitle text-gray">
                                            Avenida Diagonal, 589-591, 08014 Barcelona
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body text-gray text-muted">
                                    Starting at 180€
                                </div>
                            </a>
                        </div>
                        <div class="column col-3 col-sm-6">
                            <a class="card" href="http://www.booking.com/hotel/es/ac-marriott-diagonal-lilla.html?aid=1476163&checkin_monthday=25&checkin_month=6&checkin_year=2019&checkout_monthday=29&checkout_month=6&checkout_year=2019&no_rooms=1&group_adults=1&room1=A&sb_travel_purpose=busines" target="_blank">
                                <div class="card-image">
                                    <img class="img-responsive" src="../assets/img/accommodation-2.jpg">
                                </div>
                                <div class="card-header">
                                    <div class="card-title h6">
                                        <strong>
                                            AC Hotel Diagonal L´Illa, a Marriott Lifestyle Hotel
                                        </strong>
                                        <div class="card-subtitle text-gray">
                                            Avinguda Diagonal, 555, 08029 Barcelona
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body text-gray text-muted">
                                    Starting at 145€
                                </div>
                            </a>
                        </div>
                        <div class="column col-3 col-sm-6">
                            <a class="card" href="http://www.booking.com/hotel/es/nh-constanza.html?aid=1476163&checkin_monthday=25&checkin_month=6&checkin_year=2019&checkout_monthday=29&checkout_month=6&checkout_year=2019&no_rooms=1&group_adults=1&room1=A&sb_travel_purpose=business" target="_blank">
                                <div class="card-image">
                                    <img class="img-responsive" src="../assets/img/accommodation-3.jpg">
                                </div>
                                <div class="card-header">
                                    <div class="card-title h6">
                                        <strong>
                                            NH Collection Barcelona Constanza
                                        </strong>
                                        <div class="card-subtitle text-gray">
                                            Carrer Deu i Mata, 69-99, 08029 Barcelona
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body text-gray text-muted">
                                    Starting at 130€
                                </div>
                            </a>
                        </div>
                        <div class="column col-3 col-sm-6">
                            <a class="card" href="http://www.booking.com/hotel/es/albergue-pere-tarres.html?aid=1476163&checkin_monthday=25&checkin_month=6&checkin_year=2019&checkout_monthday=29&checkout_month=6&checkout_year=2019&no_rooms=1&group_adults=1&room1=A&sb_travel_purpose=business" target="_blank">
                                <div class="card-image">
                                    <img class="img-responsive" src="../assets/img/accommodation-4.jpg">
                                </div>
                                <div class="card-header">
                                    <div class="card-title h6">
                                        <strong>
                                            Alberg Pere Tarrés Hostel
                                        </strong>
                                        <div class="card-subtitle text-gray">
                                            Carrer de Numancia, 149, 08029 Barcelona
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body text-gray text-muted">
                                    Starting at 25€
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <p>
                    Interested in other options close to our venue?
                </p>
                <a class="btn btn-primary" href="https://www.booking.com/searchresults.en-us.html?src=index&rows=20&aid=1476163&sb=1&checkin_monthday=25&checkin_month=6&checkin_year=2019&checkin_year_month=2019-6&checkout_monthday=29&checkout_month=6&checkout_year=2019&checkout_year_month=2019-6&checkin=2019-06-25&checkout=2019-06-29&room1=A&no_rooms=1&group_adults=1&group_children=0&search_form_id=5c8140a3c7e9008a&sb_travel_purpose=business&latitude=41.3894656&longitude=2.136988400000064&ss_raw=axa+auditor&ss_label=Auditorio+AXA%2C+Avinguda+Diagonal%2C+Barcelona%2C+Spain&ac_upa=1&place_id=ChIJIVb2wHqYpBIRejvL-19GozY&place_id_lon=2.136988400000064&place_id_lat=41.3894656&place_types=establishment%2Cpoint_of_interest&ac_position=0&ac_suggestion_list_length=1&search_selected=true&search_pageview_id=5c8140a3c7e9008a" target="_blank">
                    Find hotels nearby
                </a>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'Accommodation',
    };
</script>

<style scoped>

</style>
