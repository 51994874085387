<template>
    <section class="section section-content section-activities">
        <div class="container grid-lg">

            <div class="">
                <div class="section-title h2">
                    <h3 class="h2">
                        <strong>Error</strong>
                    </h3>
                    <div class="subtitle">
                        Your ADDC <span class="highlight">ticket ID is already used</span>.
                        <div>
                            <small class="text-muted">
                                *Contact our support to resolve this issue.
                            </small>
                        </div>
                    </div>
                </div>
                <IntercomButton class="btn btn-primary">
                    <i class="icon icon-bubble"></i>
                    Open chat
                </IntercomButton>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'Accommodation',
    };
</script>

<style scoped>

</style>
