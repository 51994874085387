<template>
    <div>
        <div class="hamburger hamburger--spin" v-on:click.prevent="isOpen = !isOpen" v-bind:class="{ 'is-active': isOpen }">
            <div class="hamburger-box">
                <div class="hamburger-inner"></div>
            </div>
        </div>
        <div class="main-nav" v-show="isOpen">
            <h3 class="h2">Navigation</h3>
            <div class="main-nav-content">

                <div class="columns">

                    <div class="column col-3 col-md-6 col-sm-12">
                        <router-link class="card" to="/" title="Home">
                            <div class="card-body">
                                <div class="artwork-icon">
                                    🏡
                                </div>
                                <div class="section-title h4">
                                    <h4 class="h4">
                                        <strong>Home</strong>
                                    </h4>
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div class="column col-3 col-md-6 col-sm-12">
                        <router-link class="card" to="/tickets/" title="Get tickets">
                            <div class="card-body">
                                <div class="artwork-icon">
                                    🎟
                                </div>
                                <div class="section-title h4">
                                    <h4 class="h4">
                                        <strong>Tickets</strong>
                                    </h4>
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <div class="column col-3 col-md-6 col-sm-12">
                        <router-link class="card" to="/speakers/" title="Speakers">
                            <div class="card-body">
                                <div class="artwork-icon">
                                    🎤
                                </div>
                                <div class="section-title h4">
                                    <h4 class="h4">
                                        <strong>Speakers</strong>
                                    </h4>
                                </div>
                            </div>
                        </router-link>
                    </div>

                    <!-- <div class="column col-3 col-md-6 col-sm-12">
                        <router-link class="card" to="/talks/" title="Talks">
                            <div class="card-body">
                                <div class="artwork-icon">
                                    📺
                                </div>
                                <div class="section-title h4">
                                    <h4 class="h4">
                                        <strong>Talks</strong>
                                    </h4>
                                </div>
                            </div>
                        </router-link>
                    </div> -->

                    <div class="column col-3 col-md-6 col-sm-12">
                        <router-link class="card" to="/schedule/" title="Schedule">
                            <div class="card-body">
                                <div class="artwork-icon">
                                    🗓
                                </div>
                                <div class="section-title h4">
                                    <h4 class="h4">
                                        <strong>Schedule</strong>
                                    </h4>
                                </div>
                            </div>
                        </router-link>
                    </div>

                  <div class="column col-3 col-md-6 col-sm-12">
                  <router-link class="card" to="/jobs/" title="Jobs">
                    <div class="card-body">
                      <div class="artwork-icon">
                        🧑‍💻
                      </div>
                      <div class="section-title h4">
                        <h4 class="h4">
                          <strong>Jobs - Shapr3D</strong>
                        </h4>
                      </div>
                    </div>
                  </router-link>
                  
                </div>

                <div class="column col-3 col-md-6 col-sm-12">
                  <router-link class="card" to="/jobs-beams/" title="Jobs">
                    <div class="card-body">
                      <div class="artwork-icon">
                        🧑‍💻
                      </div>
                      <div class="section-title h4">
                        <h4 class="h4">
                          <strong>Jobs - Beams</strong>
                        </h4>
                      </div>
                    </div>
                  </router-link>
                </div>
                    <!-- <div class="column col-3 col-md-6 col-sm-12">
                        <router-link class="card" to="/workshops/" title="Workshops">
                            <div class="card-body">
                                <div class="artwork-icon">
                                    👩‍💻
                                </div>
                                <div class="section-title h4">
                                    <h4 class="h4">
                                        <strong>Workshops</strong>
                                    </h4>
                                </div>
                            </div>
                        </router-link>
                    </div> -->

                    <!-- <div class="column col-3 col-md-6 col-sm-12">
                        <router-link class="card" to="/activities/" title="Activities">
                            <div class="card-body">
                                <div class="artwork-icon">
                                    🏖
                                </div>
                                <div class="section-title h4">
                                    <h4 class="h4">
                                        <strong>Activities</strong>
                                    </h4>
                                </div>
                            </div>
                        </router-link>
                    </div> -->

<!--                    <div class="column col-3 col-md-6 col-sm-12">-->
<!--                        <router-link class="card" to="/venue/" title="Venue">-->
<!--                            <div class="card-body">-->
<!--                                <div class="artwork-icon">-->
<!--                                    🏢-->
<!--                                </div>-->
<!--                                <div class="section-title h4">-->
<!--                                    <h4 class="h4">-->
<!--                                        <strong>Venue</strong>-->
<!--                                    </h4>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </router-link>-->
<!--                    </div>-->

<!--                    <div class="column col-3 col-md-6 col-sm-12">-->
<!--                        <router-link class="card" to="/party/" title="Party">-->
<!--                            <div class="card-body">-->
<!--                                <div class="artwork-icon">-->
<!--                                    🎉-->
<!--                                </div>-->
<!--                                <div class="section-title h4">-->
<!--                                    <h4 class="h4">-->
<!--                                        <strong>Party</strong>-->
<!--                                    </h4>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </router-link>-->
<!--                    </div>-->

                    <div class="column col-3 col-md-6 col-sm-12">
                        <router-link class="card" to="/sponsors/" title="Sponsors">
                            <div class="card-body">
                                <div class="artwork-icon">
                                    🤗
                                </div>
                                <div class="section-title h4">
                                    <h4 class="h4">
                                        <strong>Sponsors</strong>
                                    </h4>
                                </div>
                            </div>
                        </router-link>
                    </div>

<!--                    <div class="column col-3 col-md-6 col-sm-12">-->
<!--                        <router-link class="card" to="/accommodation/" title="Accommodation">-->
<!--                            <div class="card-body">-->
<!--                                <div class="artwork-icon">-->
<!--                                    🛏-->
<!--                                </div>-->
<!--                                <div class="section-title h4">-->
<!--                                    <h4 class="h4">-->
<!--                                        <strong>Accommodation</strong>-->
<!--                                    </h4>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </router-link>-->
<!--                    </div>-->

<!--                    <div class="column col-3 col-md-6 col-sm-12">-->
<!--                        <router-link class="card" to="/app/" title="App">-->
<!--                            <div class="card-body">-->
<!--                                <div class="artwork-icon">-->
<!--                                    📱-->
<!--                                </div>-->
<!--                                <div class="section-title h4">-->
<!--                                    <h4 class="h4">-->
<!--                                        <strong>Mobile Apps</strong>-->
<!--                                    </h4>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </router-link>-->
<!--                    </div>-->

                </div>

            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                isOpen: false,
            };
        },
    };
</script>
