<template>
    <div class="page page-speakers">

        <Menu />

        <NavigationBar back-link="/" back-text="Home"/>

        <Speakers/>

        <Footer/>
    </div>
</template>
<script>
    import Speakers from '../components/Speakers.vue';
    import MetaData from '../mixins/MetaData.vue';

    export default {
        mixins: [MetaData],
        components: {
            Speakers,
        },
        computed: {
            seo() {
                return {
                    title: 'Speakers',
                    url: 'speakers/',
                };
            },
        },
    };
</script>
