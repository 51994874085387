<template>
    <div class="page page-talk">

        <Menu />

        <NavigationBar back-link="/speakers/" back-text="Speakers"/>

        <section class="section section-content">

            <div class="container grid-sm">
                <div class="section-title h2 text-left">
                    <h1 class="h2">
                        <strong>{{ talk.title }}</strong>
                    </h1>
                </div>

                <div class="speaker-recap" v-if="talk.youtube">
                    <div class="talk-video">
                        <div class="card">
                            <iframe width="560" height="315" v-bind:src="`https://www.youtube.com/embed/${talk.youtube}`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="text-center" v-if="talk.slides">
                        <a class="btn" v-bind:href="talk.slides" target="_blank" rel="nofollow">
                            <i class="icon icon-presentation"></i> Get slides
                        </a>
                    </div>
                </div>

                <div class="divider divider-gradient"></div>

                <div class="section-title h2 text-left">
                    <div class="subtitle talkDescription">{{ talk.description }}</div>
                </div>

                <div class="share">
                    <div>
                        <div class="fb-share-button" v-bind:data-href="talkURL" data-layout="button" data-size="large" data-mobile-iframe="true">
                            <a target="_blank" v-bind:href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(talkURL)}&amp;src=sdkpreparse`" class="fb-xfbml-parse-ignore">Share</a></div>
                    </div>
                    <div>
                        <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" class="twitter-share-button" data-size="large" v-bind:data-text="`Check out &quot;${talk.title}&quot; at @addconf`" v-bind:data-url="talkURL" data-via="addconf" data-dnt="true" data-show-count="false">Tweet</a>
                    </div>
                </div>
                <div v-if="speakers.length">
                    <label class="text-muted">
                        Presented by
                    </label>
                </div>
                <div class="speaker-nav" v-if="speakers.length">
                    <router-link v-for="speaker in speakers" v-bind:to="`/speakers/${speaker.slug}/`" v-bind:title="`${speaker.firstName} ${speaker.lastName}`">
                        <div class="tile tile-centered">
                            <div class="tile-icon">
                                <img class="avatar avatar-lg" v-bind:src="pathForImage(speaker.imageAvatar)" />
                            </div>
                            <div class="tile-content">
                                <strong>{{ speaker.firstName }}</strong> {{ speaker.lastName }}
                                <div>
                                    <small class="text-muted">
                                        {{ speaker.company }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>



            </div>

        </section>

        <Footer/>
    </div>
</template>
<style>
    .talkDescription {
        white-space: pre-wrap;
    }
</style>
<script>
    import MetaData from '../mixins/MetaData.vue';
    import Social from '../mixins/Social.vue';
    import { getTalkBySlug } from '../store/talks';
    import { getSpeakerBySlug } from '../store/speakers';
    import { getWorkshopBySlug } from '../store/workshops';

    export default {
        mixins: [MetaData, Social],
        mounted() {
            if (!this.talk) {
                this.$router.push('/schedule/');
            }
        },
        computed: {
            talk() {
              return getTalkBySlug(this.$route.params.slug) || getWorkshopBySlug(this.$route.params.slug);
            },
            speakers() {
                return this.talk.speakersSlugs.map((slug) => getSpeakerBySlug(slug));
            },
            seo() {
                return {
                    title: this.talk.title,
                    url: `schedule/${this.$route.params.slug}/`,
                    description: this.talk.description,
                };
            },
            talkURL() {
                return `https://addconf.com/2021/schedule/${this.talk.slug}/`;
            },
        },
        methods: {
            pathForImage(imageName) {
                return require(`../assets/img/speakers/${imageName}`);
            },
        },
    };
</script>
