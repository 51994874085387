<template>
    <section class="section section-venue section-inverted">
        <div class="container grid-lg">

            <div class="columns">
                <div class="column">
                    <div class="gallery">
                        <img class="gallery-front img-responsive" src="../assets/img/venue-front.jpg" alt="Why" />
                        <img class="gallery-back img-responsive" src="../assets/img/venue-back.jpg" alt="Why" />
                    </div>
                </div>
                <div class="column">
                    <div class="section-title h2">
                        <h3 class="h2">
                            The <strong>Venue</strong>
                        </h3>
                        <div class="subtitle">
                            <a href="https://goo.gl/maps/NE7ivN5AZQM2" target="_blank" class="text-white">L'illa Diagonal, Avinguda Diagonal, 547, 08029 <span class="highlight"><strong>Barcelona</strong>, Spain</span></a>
                        </div>
                    </div>
                    <p>
                        The AXA Convention Centre is part of an enormous complex located on the main artery of Barcelona.
                    </p>
                    <p>
                        The avant-garde design and construction quality emerge from each and every detail of the building, turning the l’ILLA complex into an emblematic landmark of the city.
                    </p>
                    <p>
                        City transportation is excellent and access from Barcelona’s Airport and Sants Station is very quick.
                    </p>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'Venue',
    };
</script>

<style scoped>
    .text-white {
        color: white !important;
    }
    .text-white:hover {
        opacity: 0.6;
    }
</style>
