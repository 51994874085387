<template>
<div class="page page-jobs">

<Menu />

<NavigationBar back-link="/" back-text="Home"/>

<section class="section section-jobs">

<div class="container grid-md inverted">
  <div class="section-title h1 inverted">
    <h1 class="h1">
      <strong>Jobs</strong>
    </h1>
    <div class="subtitle">
      Our sponsors are looking for top notch talent to join their team.
    </div>
  </div>

  <div>
    <div class="columns grid-left text-left">
      <div class="column col-4 col-sm-6 col-md-4">
        <a href="https://grnh.se/128ba90f3us" target="_blank" class="job-card">
          <img src="../assets/img/speakers/brand-shapr3d.svg" class="brand  img-responsive" />
          <H4>
            Senior iOS Engineer
          </H4>
          <small class="text-muted">Hungary</small>
        </a>
      </div>
      <div class="column col-4 col-sm-6 col-md-4">
        <a href="https://grnh.se/4fdc16ff3us" target="_blank" class="job-card">
          <img src="../assets/img/speakers/brand-shapr3d.svg" class="brand img-responsive" />
          <H4>
            Product Designer
          </H4>
          <small class="text-muted">Hungary</small>
        </a>
      </div>
      <div class="column col-4 col-sm-6 col-md-4">
        <a href="https://grnh.se/98aeb6a13us" target="_blank" class="job-card">
          <img src="../assets/img/speakers/brand-shapr3d.svg" class="brand img-responsive" />
          <H4>
            Product Manager
          </H4>
          <small class="text-muted">Hungary</small>
        </a>
      </div>
      <div class="column col-4 col-sm-6 col-md-4">
        <a href="https://grnh.se/2df40d143us" target="_blank" class="job-card">
          <img src="../assets/img/speakers/brand-shapr3d.svg" class="brand img-responsive" />
          <H4>
            Software Engineer, Rendering and Graphic
          </H4>
          <small class="text-muted">Hungary</small>
        </a>
      </div>
      <div class="column col-4 col-sm-6 col-md-4">
        <a href="https://grnh.se/3cb064023us" target="_blank" class="job-card">
          <img src="../assets/img/speakers/brand-shapr3d.svg" class="brand img-responsive" />
          <H4>
            UI Designer (Website)
          </H4>
          <small class="text-muted">Hungary</small>
        </a>
      </div>
    </div>
  </div>

</div>

</section>

<Footer/>
</div>
</template>
<script>
  import MetaData from '../mixins/MetaData.vue';

  export default {
    mixins: [MetaData],
    computed: {
      seo() {
        return {
          title: 'Jobs',
          url: 'jobs/',
        };
      },
    },
  };
</script>
