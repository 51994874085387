<template>
    <section class="section section-speakers">
        <div class="container grid-lg">

            <div class="section-title h2 inverted">
                <h3 class="h2">
                    <strong>Speakers</strong>
                </h3>
                <div class="subtitle">
                    <!-- Check out our diverse selection of speakers. -->
                    <div class="text-muted">
                        Our talks are crafted to be valuable for both designers & developers.
                    </div>
                    <small class="text-muted">*We will announce more speakers soon.</small>


                </div>
            </div>

            <div class="speaker-list">
                <div class="columns grid-center">
                    <div class="column col-3 col-sm-6 col-md-4" v-for="speaker in speakers">
                        <router-link class="speaker-item" v-bind:to="`/speakers/${speaker.slug}`">
                            <img class="avatar" v-bind:src="pathForImage(speaker.imageAvatar)">
                            <div class="section-title h5 inverted">
                                <h2 class="h5">
                                    <strong>{{ speaker.firstName }} {{ speaker.lastName }}</strong>
                                </h2>
                                <div class="subtitle">
                                    <span class="text-muted">{{ speaker.headline }}</span> <span v-if="speaker.company"> at <strong>{{ speaker.company }}</strong></span>
                                </div>
                            </div>
                            <img class="brand" v-if="speaker.imageCompany" v-bind:src="pathForImage(speaker.imageCompany)">
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="announcements">
                <div class="columns grid-center">
                    <!-- <div class="column col-6 col-sm-12">
                        <a class="card" href="https://addconf.typeform.com/to/o6vc1V" target="_blank">
                            <div class="card-body">
                                <div class="artwork-icon">
                                    ⭐️
                                </div>
                                <div class="section-title h4">
                                    <div class="h4">
                                        <strong>Recommend &amp; join for free</strong>
                                    </div>
                                    <div class="subtitle">
                                        By December 31st nominate a <strong>speaker you love</strong> to be invited to the ADDC and
                                        get <strong>a free ADDC ticket</strong>.
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div> -->
                    <!-- <div class="column col-6 col-sm-12">
                        <a class="card" href="https://addconf.typeform.com/to/UYLfhu" target="_blank">
                            <div class="card-body">
                                <div class="artwork-icon">
                                    🎤
                                </div>
                                <div class="section-title h4">
                                    <div class="h4">
                                        <strong>Apply & become a speaker</strong>
                                    </div>
                                    <div class="subtitle">
                                        Check out our <strong>Call for Proposals</strong> and submit your
                                        idea until <strong>February 15th</strong> to be considered for speaking at ADDC.
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div> -->
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    import speakers from '../store/speakers';
    import talks from '../store/talks';

    export default {
        name: 'Speakers',
        data() {
            return {
                speakers, talks,
            };
        },
        methods: {
            pathForImage(imageName) {
                return require(`../assets/img/speakers/${imageName}`);
            },
        },
    };
</script>

<style scoped>

</style>
