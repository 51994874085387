<template>
    <section class="section section-content section-schedule">
        <div class="container grid-lg">

            <div>

                <div class="section-title h2">
                    <h3 class="h2">
                        <strong>Schedule</strong>
                    </h3>
                    <div class="subtitle">

                       Two days of <span class="highlight">inspiring talks</span>

                        <div>
                            <small class="text-muted">*The schedule is subject to change.</small>
                        </div>
                    </div>
                </div>

                <div class="schedule">
                    <div class="columns">
                        <div class="column col-6 col-md-12">
                            <div class="section-title h4">
                                <div>
                                    <strong>June 23</strong>
                                </div>
                                <div class="subtitle text-muted">
                                    Wednesday
                                </div>
                            </div>
                            <div>
                                🤗 Opening<br />
                                🎤 Inspiring talks
                            </div>
                        </div>
                        <div class="column col-6 col-md-12">
                            <div class="section-title h4">
                                <div>
                                    <strong>June 24</strong>
                                </div>
                                <div class="subtitle text-muted">
                                    Thursday
                                </div>
                            </div>
                            <div>
                                🎤 Inspiring talks<br />
                                🙌🏼 Closing
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-center">
                    <div class="divider"></div>
                    <div class="actions">
                        <router-link to="/tickets/" class="btn btn-primary btn-lg">
                            🎟 Buy tickets
                        </router-link>
                        <router-link to="/schedule/" class="btn">
                            View full schedule
                        </router-link>
                    </div>
                    <div class="divider divider-gradient"></div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ScheduleHome',
    };
</script>

<style scoped>

</style>
