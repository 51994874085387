<template>
    <div class="page">

        <Menu />

        <NavigationBar back-link="/" back-text="Home"/>

        <section class="section section-content">

            <div class="container grid-sm">
                <div class="section-title h1">
                    <h1 class="h1">
                        <strong>Impressum</strong>
                    </h1>
                    <div class="subtitle">
                        Angaben gemäß § 5 TMG:
                    </div>
                </div>
                <p>
                    <strong>FIFTY FIFTY INNOVATIONS</strong> UG (haftungsbeschränkt)<br />
                    Hermann-Hesse-Str. 25A<br />
                    13156 Berlin<br />
                    Germany
                </p>
                <p>
                    Geschäftsführer: Matej Balantič<br />
                    Handelsregisternummer: AG Berlin-Charlottenburg HRB 182810 B
                </p>
                <p>
                    USt-IdNr. Deutschland: DE 309 942 216<br />
                    NIF-VAT ID Spain: ES N2763443E
                </p>
                <p>
                    Contact: <a href="mailto:info@addconf.com">info@addconf.com</a>
                </p>
                <div class="divider divider-gradient"></div>
                <div class="section-title h5">
                    <div>
                        <strong>Alternative Streitbeilegung</strong>
                    </div>
                    <div class="subtitle">
                        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter <a href="http://ec.europa.eu/consumers/odr/" target="_blank">http://ec.europa.eu/consumers/odr/</a> finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
                    </div>
                </div>
            </div>

        </section>

        <Footer/>
    </div>
</template>
<script>
    import MetaData from '../mixins/MetaData.vue';

    export default {
        mixins: [MetaData],
        computed: {
            seo() {
                return {
                    title: 'Impressum',
                    url: 'impressum/',
                };
            },
        },
    };
</script>
