<template>
    <div class="page page-tickets">

        <Menu />

        <NavigationBar back-link="/" back-text="Home"/>

        <Tickets/>

        <Footer/>
    </div>
</template>
<script>
    import Tickets from '../components/Tickets.vue';
    import MetaData from '../mixins/MetaData.vue';

    export default {
        mixins: [MetaData],
        components: {
            Tickets,
        },
        computed: {
            seo() {
                return {
                    title: 'Tickets',
                    url: 'tickets/',
                };
            },
        },
    };
</script>
