<template>

    <section class="section section-content">
        <div class="container grid-sm text-center">

            <div class="section-title h3">
                <h3 class="h3">
                    <strong>Mobile Apps</strong>
                </h3>
                <div class="subtitle">
                    Download our mobile apps to follow the news, check out the schedule, read about the speakers and be informed on the fly about any changes right on your mobile phone.
                </div>
            </div>

            <div class="divider divider-gradient">

            </div>

            <div class="download-app-buttons">
                <div class="columns grid-center">
                    <div class="column col-4">
                        <a href="https://itunes.apple.com/us/app/app-design-development-conference-addc-2017/id1240808347?mt=8" target="_blank">
                            <img class="img-responsive" src="../assets/img/store-badge-apple.svg" alt="Apple App Store" />
                        </a>
                    </div>
                    <div class="column col-4">
                        <a href="https://play.google.com/store/apps/details?id=com.addconf.app" target="_blank">
                            <img class="img-responsive" src="../assets/img/store-badge-google.svg" alt="Google Play Store" />
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'App',
    };
</script>

<style scoped>

</style>
