<template>
    <div class="page page-sponsorship sponsorship">

        <Menu />

        <NavigationBar back-link="/" back-text="Home"/>

        <section class="section section-sponsorship section-inverted">

            <div class="container grid-lg">
                <div class="section-title h2">
                    <h2 class="h2">
                        <strong>Become a sponsor</strong>
                    </h2>
                    <div class="subtitle">
                        Sponsorship packages for <strong>ADDC 2021 edition</strong>
                        <div>
                            <small>
                                Feel free to contact us and discuss a sponsorship package that is tailored to you.
                            </small>
                        </div>
                    </div>
                </div>

                <div class="facts">
                    <label>
                        <strong>Quick Facts</strong>
                    </label>
                    <div class="facts-list">
                        <div class="columns">
                            <div class="column col-3 col-md-12">
                                <div class="panel">
                                    <div class="panel-header">
                                        A single track multidisciplinary event for designers and developers, established in 2017
                                    </div>
                                </div>
                            </div>
                            <div class="column col-3 col-md-12">
                                <div class="panel">
                                    <div class="panel-header">
                                        Number of attendees: 200 in 2017, 350 in 2018, expecting between 500 and 600 in 2019
                                    </div>
                                </div>
                            </div>
                            <div class="column col-3 col-md-12">
                                <div class="panel">
                                    <div class="panel-header">
                                        Attendees break down by role: UX/UI Designers 35% | iOS Developers 24% | Android Developers 18% | Others 23%
                                    </div>
                                </div>
                            </div>
                            <div class="column col-3 col-md-12">
                                <div class="panel">
                                    <div class="panel-header">
                                        Past sponsors: Google, SoundCloud, Microsoft, Sketch, JET BRAINS, InstaX and many others
                                    </div>
                                </div>
                            </div>
                            <div class="column col-12">
                                <div class="panel">
                                    <div class="panel-header">
                                        Attendees from many industry leading companies attended in the past, including: SoundCloud, Raiffeisen, Financial Times, Bosch, Uber, Facebook, Shopify,  Google, Booking.com, Dropbox, Strava, Spotify, Blacklane, Sportradar, REWE, AxelSpringer, Volkswagen
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="columns">
                    <div class="column col-4 col-md-12">
                        <div class="offer offer-platinum">
                            <div class="section-title h3">
                                <h3 class="h3">
                                    <strong>Patron</strong>
                                </h3>
                            </div>
                            <div class="divider"></div>
                            <label class="text-muted">
                                What's included
                            </label>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Your logo present in all our publications, website, app
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Your flyer in our attendee bag
                                </div>
                            </div>
                            <div class="divider"></div>
                            <div class="price text-center">
                                <div class="section-title h2">
                                    <div class="subtitle">
                                        <small class="text-muted">Price</small>
                                    </div>
                                    <h2 class="h2">
                                        <strong>399</strong><small>EUR</small>
                                    </h2>
                                    <div class="subtitle">
                                        <small class="text-muted">
                                            * Excluding any applicable VAT
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="divider divider-gradient"></div>
                            <a class="btn btn-primary btn-lg" href="#!" v-on:click.prevent="openRequest('Patron package')">
                                Request package
                            </a>
                        </div>
                    </div>
                    <div class="column col-4 col-md-12">
                        <div class="offer offer-gold">
                            <div class="section-title h3">
                                <h3 class="h3">
                                    <strong>Gold</strong>
                                </h3>
                            </div>
                            <div class="divider"></div>
                            <label class="text-muted">
                                What's included
                            </label>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Your logo present in all our publications, website, app
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Your flyer in our attendee bag
                                </div>
                            </div>
                            <div class="divider"></div>
                            <label class="text-muted">
                                Exclusive to Gold package
                            </label>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    3 conference tickets
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Mention in email and social media
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Stand
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-content">
                                    <div class="price text-center">
                                        <div class="section-title h4">
                                            <div class="subtitle">
                                                <small class="text-muted">Base price</small>
                                            </div>
                                            <h4 class="h4">
                                                <strong>2.999</strong><small>EUR</small>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="divider"></div>
                            <label class="text-muted">
                                Add on-stage promotion
                            </label>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon" v-bind:class="{ 'icon-plus': !extraPackageSelected, 'icon-check': extraPackageSelected }"></i>
                                </div>
                                <div class="tile-content">
                                    <strong>5 min</strong> sponsored product/company pitch on stage
                                </div>
                                <div class="tile-action">
                                    <div class="form-group">
                                        <label class="form-switch">
                                            <input type="checkbox" v-model="extraPackageSelected">
                                            <i class="form-icon"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-content">
                                    <div class="price text-center">
                                        <div class="section-title h4">
                                            <div class="subtitle">
                                                <small class="text-muted">Add-on price</small>
                                            </div>
                                            <h4 class="h4">
                                                <strong>3.999</strong><small>EUR</small>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="divider"></div>
                            <div class="price text-center">
                                <div class="section-title h2">
                                    <div class="subtitle">
                                        <small class="text-muted">Total price</small>
                                    </div>
                                    <h2 class="h2">
                                        <strong>{{ !extraPackageSelected ? '2.999' : '6.998' }}</strong><small>EUR</small>
                                    </h2>
                                    <div class="subtitle">
                                        <small class="text-muted">
                                            * Excluding any applicable VAT
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="divider divider-gradient"></div>
                            <a class="btn btn-primary btn-lg" href="#!"v-on:click.prevent="openRequest('Gold package' + (extraPackageSelected ? ' with on-stage promotion add-on' : '') )">
                                Request package
                            </a>
                        </div>
                    </div>
                    <div class="column col-4 col-md-12">
                        <div class="offer offer-patreon">
                            <div class="section-title h3">
                                <h3 class="h3">
                                    <strong>Partner</strong>
                                </h3>
                            </div>
                            <div class="divider"></div>
                            <label class="text-muted">
                                What's included
                            </label>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Your logo present in all our publications, website, app
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Your flyer in our attendee bag
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Conference tickets
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Mention in email and social media
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Stand
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    <strong>5 min</strong> sponsored product/company pitch on stage
                                </div>
                            </div>
                            <div class="divider"></div>
                            <label class="text-muted">
                                Exclusive to Partners
                            </label>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Published as an <strong>official partner</strong> of the event next to the ADDC brand
                                </div>
                            </div>
                            <!-- <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Invitation to participate in the <strong>main panel discussion</strong>
                                </div>
                            </div> -->
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Option to <strong>promote your brand in the community</strong> by enabling selected attendees to attend the event. We'll organize an application proccess in which you select the attendees that you'd like to sponsor (travel, accommodation and the ticket).
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Option to organize an invite-only <strong>satelite event</strong> (e.g. panel, workshop, activity).
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-icon">
                                    <i class="icon icon-check"></i>
                                </div>
                                <div class="tile-content">
                                    Option to brand a <strong>special activity</strong> during the event like the conference party, play corner, etc.
                                </div>
                            </div>
                            <div class="divider"></div>
                            <div class="price text-center">
                                <div class="section-title h2">
                                    <div class="subtitle">
                                        <small class="text-muted">Price</small>
                                    </div>
                                    <h2 class="h2">
                                        <small>Contact us</small>
                                    </h2>
                                </div>
                            </div>
                            <div class="divider divider-gradient"></div>
                            <a class="btn btn-primary btn-lg" href="#!" v-on:click.prevent="openRequest('Partner package')">
                                Request package
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer/>
    </div>

</template>
<script>
    import MetaData from '../mixins/MetaData.vue';

    export default {
        mixins: [MetaData],
        data() {
            return {
                extraPackageSelected: false,
            };
        },
        methods: {
            openRequest(packageTitle) {
                Intercom('showNewMessage', `Hi, I am interested to sign up for ${packageTitle}.`);
            },
        },
        computed: {
            seo() {
                return {
                    title: 'Become a sponsor',
                    url: 'become-a-sponsor/',
                };
            },
        },
    };
</script>
