<template>
    <section class="section section-party section-inverted">
        <div class="container grid-lg">

            <div class="party-teaser text-center">
                <div class="columns grid-center">

                    <div class="column col-7">
                        <div class="section-title h2">
                            <h3 class="h2">
                                Conference <strong>Party</strong>
                            </h3>
                            <small class="highlight">
                                Included in your ticket
                            </small>
                        </div>

                        <p>
                            The ADDC party is hosted in one of the most amazing places in Barcelona, Poble Espanyol - a recreated Spanish village in the heart of Barcelona with stunning views of the city.
                        </p>

                        <p>
                            <small class="text-muted">
                                *Party details are subject to change
                            </small>
                        </p>

                        <router-link to="/party" class="btn bnt-lg">
                            Read more
                        </router-link>

                    </div>

                    <div class="column col-12">

                        <div class="gallery-party">
                            <div class="columns">
                                <div class="column col-6 col-sm-12">
                                    <img class="img-responsive" src="../assets/img/party/party-1.jpg" alt="Party" />
                                </div>
                                <div class="column col-6 col-sm-12">
                                    <img class="img-responsive" src="../assets/img/party/party-2.jpg" alt="Party" />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'Venue',
    };
</script>

<style scoped>
    .text-white {
        color: white !important;
    }
    .text-white:hover {
        opacity: 0.6;
    }
</style>
